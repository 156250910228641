import { ResourceService } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { IUser } from './UserService.props';

export class UserServiceImplementation extends ResourceService<IUser> {
  me = async () => {
    const result = await this.client.get<IUser>(`${this.endpoint}/me`);

    return result.data;
  };

  updateMe = async (data: Partial<IUser>) => {
    const result = await this.client.put<IUser>(`${this.endpoint}/${data.id}`, data);

    return result.data;
  };
}

export const UserService = new UserServiceImplementation(AppService, '/admin/users');
