import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { QuestionnaireConfigurator } from '@Components/modules';
import { QuestionnaireType } from '@Services/Questionnaire';

export const QuestionnairePersonaPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('QUESTIONNAIRE.TYPE.PERSONA')}>
      <Card title={t('QUESTIONNAIRE.TYPE.PERSONA')}>
        <QuestionnaireConfigurator type={QuestionnaireType.PERSONA} />
      </Card>
    </Page>
  );
};
