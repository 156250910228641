import { ResourceService } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { IQuestionnaireAnswer } from './QuestionnaireAnswerService.props';

export class QuestionnaireAnswerServiceImplementation extends ResourceService<IQuestionnaireAnswer> {
  moveUp = async (id: string) => {
    const response = await this.client.post<IQuestionnaireAnswer>(`${this.endpoint}/${id}/moveup`);

    return response.data;
  };

  moveDown = async (id: string) => {
    const response = await this.client.post<IQuestionnaireAnswer>(
      `${this.endpoint}/${id}/movedown`,
    );

    return response.data;
  };
}

export const QuestionnaireAnswerService = new QuestionnaireAnswerServiceImplementation(
  AppService,
  '/admin/questionnaireanswers',
);
