import AUTHENTICATION from './authentication.json';
import COMPONENTS from './components.json';
import FORMS from './forms.json';
import GLOBAL from './global.json';
import RESOURCE from './resource.json';
import TABLE from './table.json';
import VALIDATION from './validation.json';

const data = {
  translation: {
    AUTHENTICATION,
    GLOBAL,
    TABLE,
    RESOURCE,
    FORMS,
    VALIDATION,
    COMPONENTS,
  },
};

export default data;
