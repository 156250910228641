import cn from 'classnames';
import { remove } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopupDelete } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { formatNumberWithLeadingZero } from '@socialbrothers/utils';

import { useQuestionnaireConfigurator } from '@Hooks/index';
import {
  IQuestionnaireQuestion,
  QuestionnaireQuestionService,
} from '@Services/QuestionnaireQuestion';
import { hasAnswers } from '@Utils/QuestionnaireUtils';

import { AnswerForm } from '../AnswerForm/AnswerForm';
import { AnswerView } from '../AnswerView/AnswerView';
import { QuestionForm } from '../QuestionForm/QuestionForm';
import styles from './QuestionView.module.scss';
import { QuestionViewMoveProps, QuestionViewProps } from './QuestionView.props';

export const QuestionView = ({ questions, section }: QuestionViewProps) => {
  const { t } = useTranslation();
  const [openQuestions, setOpenQuestions] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const questionnaire = useQuestionnaireConfigurator();

  const mutateMove = useMutation(
    (values: QuestionViewMoveProps) => {
      if (values.direction === 'up') {
        return QuestionnaireQuestionService.moveUp(values.id);
      } else {
        return QuestionnaireQuestionService.moveDown(values.id);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);
      },

      onError: () => {
        toast.error(t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.MOVE_FAILED'));
      },
    },
  );

  const handleMove = useCallback(
    (values: QuestionViewMoveProps) => () => {
      mutateMove.mutateAsync(values);
    },
    [mutateMove],
  );

  const mutateDuplicate = useMutation(
    (id: string) => {
      return QuestionnaireQuestionService.duplicate(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);
        toast.success(
          t('GLOBAL.DUPLICATED_SUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.SINGLE'),
          }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.DUPLICATED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.SINGLE'),
          }),
        );
      },
    },
  );

  const mutateDelete = useMutation(
    (id: string) => {
      return QuestionnaireQuestionService.delete(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);
        toast.success(
          t('GLOBAL.DELETED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.SINGLE') }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.DELETED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.SINGLE'),
          }),
        );
      },
    },
  );

  const handleDelete = useCallback(
    (id: string) => async () => {
      return mutateDelete.mutateAsync(id);
    },
    [mutateDelete],
  );

  const handleDuplicate = useCallback(
    (id: string) => async () => {
      return mutateDuplicate.mutateAsync(id);
    },
    [mutateDuplicate],
  );

  const handleQuestionToggle = useCallback(
    (id: string) => () => {
      setOpenQuestions((oldOpenQuestions) => {
        const newExpandedSteps = [...oldOpenQuestions];

        if (newExpandedSteps.includes(id)) {
          remove(newExpandedSteps, (value: string) => value === id);
        } else {
          newExpandedSteps.push(id);
        }

        return newExpandedSteps;
      });
    },
    [],
  );

  const getQuestion = useCallback(
    (question: IQuestionnaireQuestion, index: number, length: number) => (
      <div key={question.id} className={cn([styles.Questions__Item, styles.Question])}>
        <div className={styles.Question__Header}>
          <h5 className={styles.Question__Title}>
            <div>
              {t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.TITLE', {
                title:
                  question.title.length <= 0
                    ? t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.NO_TITLE')
                    : question.title,
                index: formatNumberWithLeadingZero(index + 1),
              })}
            </div>
            <small>{t(`ENUM.QUESTIONNAIRE_QUESTION_TYPE.${question.type}`)}</small>
          </h5>

          <div className={styles.Question__Buttons}>
            {hasAnswers(question.type) && (
              <Button
                onClick={handleQuestionToggle(question.id)}
                icon={openQuestions.includes(question.id) ? 'eye-slash' : 'eye'}
                link
              />
            )}

            <Button
              link
              onClick={handleMove({ id: question.id, direction: 'up' })}
              disabled={index === 0}
              icon="arrow-up"
              isLoading={mutateMove.isLoading}
            />

            <Button
              link
              onClick={handleMove({ id: question.id, direction: 'down' })}
              disabled={index + 1 === length}
              icon="arrow-down"
              isLoading={mutateMove.isLoading}
            />

            <Button link onClick={handleDuplicate(question.id)} icon="copy" />

            <QuestionForm question={question} section={section} />

            <ButtonWithPopupDelete
              link
              name={question.title}
              onDelete={handleDelete(question.id)}
            />
          </div>
        </div>

        {hasAnswers(question.type) && openQuestions.includes(question.id) && (
          <div className={styles.Question__Answers}>
            <AnswerView question={question} answers={question.answers} />
            <AnswerForm question={question} />
          </div>
        )}
      </div>
    ),
    [
      handleDelete,
      handleMove,
      handleDuplicate,
      handleQuestionToggle,
      mutateMove.isLoading,
      openQuestions,
      section,
      t,
    ],
  );

  return (
    <div className={styles.Questions}>
      {questions.map((question: IQuestionnaireQuestion, index: number) => {
        return getQuestion(question, index, questions.length);
      })}
    </div>
  );
};
