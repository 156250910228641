import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { enumToOptions, modelToOptions, Yup } from '@socialbrothers/utils';

import {
  useDomains,
  usePersonaThemes,
  useQuestionnaireConfigurator,
  useThemes,
} from '@Hooks/index';
import { QuestionnaireType } from '@Services/Questionnaire';
import { QuestionnaireQuestionService } from '@Services/QuestionnaireQuestion';
import {
  IQuestionnaireSection,
  QuestionnaireSectionLinkableType,
  QuestionnaireSectionService,
} from '@Services/QuestionnaireSection';
import { getFileField } from '@Utils/FileUtils';
import { isConcertoSection } from '@Utils/QuestionnaireUtils';

import { SectionFormFormProps, SectionFormProps } from './SectionForm.props';

export const SectionForm = ({ section, parentSectionId }: SectionFormProps) => {
  const { t } = useTranslation();
  const [linkableType, setLinkableType] = useState<QuestionnaireSectionLinkableType | undefined>();
  const [isConcerto, setIsConcerto] = useState<boolean | undefined>(isConcertoSection(section));
  const [displayIntroduction, setDisplayIntroduction] = useState();
  const themes = useThemes();
  const personaThemes = usePersonaThemes();
  const domains = useDomains();

  const queryClient = useQueryClient();
  const questionnaire = useQuestionnaireConfigurator();

  const mutateCreate = useMutation(
    (values: Partial<IQuestionnaireSection>) =>
      QuestionnaireSectionService.create({
        questionnaireType: questionnaire.type,
        parentSectionId: parentSectionId,
        ...values,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);
        queryClient.invalidateQueries([
          QuestionnaireQuestionService.endpoint,
          section?.concertoQuestionTableName,
        ]);

        toast.success(
          t('GLOBAL.CREATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE') }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.CREATED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE'),
          }),
        );
      },
    },
  );

  const mutateUpdate = useMutation(
    (values: Partial<IQuestionnaireSection>) =>
      QuestionnaireSectionService.update(section ? section.id : '', values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);
        queryClient.invalidateQueries([
          QuestionnaireQuestionService.endpoint,
          section?.concertoQuestionTableName,
        ]);

        toast.success(
          t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE') }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.UPDATED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE'),
          }),
        );
      },
    },
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    description: Yup.string().when('displayIntroduction', {
      is: 'true',
      then: (s) => s.required(),
    }),
    linkableId: linkableType ? Yup.string().required() : Yup.string(),
    concertoQuestionTableName: Yup.string().when('isConcertoSection', {
      is: 'true',
      then: (s) => s.required(),
    }),
    concertoTestSlug: Yup.string().when('isConcertoSection', {
      is: 'true',
      then: (s) => s.required(),
    }),
  });

  return (
    <ButtonWithPopup
      withForm
      validationSchema={validationSchema}
      initialValues={{
        ...section,
        isConcertoSection: isConcerto,
        linkableType: section?.linkableType || '',
      }}
      button={
        section
          ? { icon: 'pencil' }
          : {
              icon: 'plus',
              label: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.CREATE'),
            }
      }
      submit={{
        label: section ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
        onClick: async (values: SectionFormFormProps) => {
          const params = {
            ...values,
            image: await getFileField(values.image),
          };

          return section ? mutateUpdate.mutateAsync(params) : mutateCreate.mutateAsync(params);
        },
      }}
      popup={{
        title: section
          ? t('GLOBAL.UPDATE_MODEL', {
              name: section.title,
            })
          : t('GLOBAL.CREATE_MODEL', {
              name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE'),
            }),
      }}>
      <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.TITLE">
        <Form.Input.Text name="title" />
      </Form.Layout.Field>

      <Form.Layout.Group label={t('QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.INTRODUCTION')}>
        <Form.Layout.Field
          translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.DISPLAY_INTRODUCTION"
          onChange={setDisplayIntroduction}>
          <Form.Input.Toggle
            name="displayIntroduction"
            label={t('QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.DISPLAY_INTRODUCTION_LABEL')}
          />
        </Form.Layout.Field>

        {displayIntroduction && (
          <>
            <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.DESCRIPTION">
              <Form.Input.RichText name="description" />
            </Form.Layout.Field>

            <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.IMAGE">
              <Form.Input.File name="image" />
            </Form.Layout.Field>
          </>
        )}
      </Form.Layout.Group>

      <Form.Layout.Group label={t('QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.LINKABLE')}>
        <Form.Layout.Field
          translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.LINKABLE_TYPE"
          onChange={setLinkableType}>
          <Form.Input.Select
            name="linkableType"
            options={[
              {
                value: t('GLOBAL.NONE'),
              },
              ...enumToOptions(
                questionnaire.type === QuestionnaireType.SELFTEST
                  ? [
                      QuestionnaireSectionLinkableType.DOMAIN,
                      QuestionnaireSectionLinkableType.THEME,
                    ]
                  : [QuestionnaireSectionLinkableType.PERSONA_THEME],
                'QUESTIONNAIRE_SECTION_LINKABLE_TYPE',
              ),
            ]}
          />
        </Form.Layout.Field>

        {linkableType === QuestionnaireSectionLinkableType.DOMAIN && domains.data && (
          <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.LINKABLE_ID_DOMAIN">
            <Form.Input.Select
              name="linkableId"
              options={modelToOptions(domains.data.results, 'title')}
            />
          </Form.Layout.Field>
        )}

        {linkableType === QuestionnaireSectionLinkableType.THEME && themes.data && (
          <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.LINKABLE_ID_THEME">
            <Form.Input.Select
              name="linkableId"
              options={modelToOptions(themes.data.results, 'title')}
            />
          </Form.Layout.Field>
        )}

        {linkableType === QuestionnaireSectionLinkableType.PERSONA_THEME && personaThemes.data && (
          <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.LINKABLE_ID_PERSONA_THEME">
            <Form.Input.Select
              name="linkableId"
              options={modelToOptions(personaThemes.data.results, 'title')}
            />
          </Form.Layout.Field>
        )}
      </Form.Layout.Group>

      <Form.Layout.Group label={t('QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.SCREENING')}>
        <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.IS_SCREENING_SECTION">
          <Form.Input.Toggle
            name="isScreeningSection"
            label={t('QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.IS_SCREENING_SECTION_LABEL')}
          />
        </Form.Layout.Field>
      </Form.Layout.Group>

      {questionnaire.type === QuestionnaireType.SELFTEST && (
        <Form.Layout.Group label={t('QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.CONCERTO')}>
          <Form.Layout.Field
            translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.IS_CONCERTO_SECTION"
            onChange={setIsConcerto}>
            <Form.Input.Toggle
              name="isConcertoSection"
              label={t('QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.IS_CONCERTO_SECTION_LABEL')}
            />
          </Form.Layout.Field>

          {isConcerto && (
            <>
              <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.CONCERTO_QUESTION_TABLE_NAME">
                <Form.Input.Text name="concertoQuestionTableName" />
              </Form.Layout.Field>

              <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.CONCERTO_TEST_SLUG">
                <Form.Input.Text name="concertoTestSlug" />
              </Form.Layout.Field>
            </>
          )}
        </Form.Layout.Group>
      )}
    </ButtonWithPopup>
  );
};
