import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { isCreate, Yup } from '@socialbrothers/utils';

import { CompanyDivisionService } from '@Services/CompanyDivision';

import { CompanyDivisionFormProps } from './CompanyDivisionForm.props';

export const CompanyDivisionForm = ({ companyId }: CompanyDivisionFormProps) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    companyId: Yup.string().required(),
    title: Yup.string().required(),
    domain: Yup.string().domain(),
    licenseLimit: Yup.number().min(1).required(),
  });

  return (
    <Form.Resource
      service={CompanyDivisionService}
      validationSchema={validationSchema}
      label={t('COMPANY_DIVISION.SINGLE')}
      id={id}>
      <Form.Input.Hidden value={companyId} name="companyId" />

      <Form.Layout.Field translationKey="COMPANY_DIVISION.LABELS.TITLE">
        <Form.Input.Text name="title" />
      </Form.Layout.Field>

      <Form.Layout.Field
        translationKey="COMPANY_DIVISION.LABELS.DOMAIN"
        prefix={t('COMPANY_DIVISION.LABELS.DOMAIN_PREFIX')}>
        <Form.Input.Text name="domain" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY_DIVISION.LABELS.LICENSE_LIMIT">
        <Form.Input.Number name="licenseLimit" min={0} />
      </Form.Layout.Field>

      {!isCreate(id) && (
        <Form.Layout.Field translationKey="COMPANY_DIVISION.LABELS.LICENSE_CODE">
          <Form.Input.Text name="licenseCode" disabled />
        </Form.Layout.Field>
      )}
    </Form.Resource>
  );
};
