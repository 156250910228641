import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { Operators } from '@socialbrothers/constants';
import { useList } from '@socialbrothers/hooks';
import { modelToOptions, Yup } from '@socialbrothers/utils';

import { CompanyService, ICompany } from '@Services/Company';
import { MiscService } from '@Services/Misc';
import { PersonaService } from '@Services/Persona';

import { ExportFormValues, ExportType } from './ExportForm.props';

export const ExportForm = ({ ...props }) => {
  const { t } = useTranslation();
  const [type, setType] = useState<ExportType>();

  const companies = useList<ICompany>(CompanyService, {
    perPage: 0,
    filtersJson: {
      isPrivate: [
        {
          operator: Operators.IS_NOT,
          value: 'true',
        },
      ],
    },
  });

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(),
  });

  const handleSubmit = async (values: ExportFormValues) => {
    if (type === ExportType.PERSONAS) {
      await PersonaService.exportPersona();
    } else {
      if (values.id) {
        await MiscService.exportCompany(values.id);
      } else {
        await MiscService.exportCompanies();
      }
    }

    toast.success(t('EXPORT.SUCCESS'));
  };

  return (
    <Form.Base
      onSubmit={handleSubmit}
      submitLabel={t('EXPORT.LABELS.SUBMIT')}
      validationSchema={validationSchema}
      {...props}>
      <Form.Layout.Field translationKey="EXPORT.LABELS.TYPE" onChange={setType}>
        <Form.Input.Select
          name="type"
          options={[
            {
              key: ExportType.PERSONAS,
              value: t('EXPORT.LABELS.PERSONA'),
            },
            {
              key: ExportType.SELFTEST,
              value: t('EXPORT.LABELS.SELFTEST'),
            },
          ]}
        />
      </Form.Layout.Field>
      {companies.data && type === ExportType.SELFTEST && (
        <Form.Layout.Field translationKey="EXPORT.LABELS.ID">
          <Form.Input.Select
            name="id"
            options={[
              { key: '', value: String(t('EXPORT.ALL_COMPANIES')), disabled: false },
            ].concat(modelToOptions(companies.data.results, 'title'))}
          />
        </Form.Layout.Field>
      )}
    </Form.Base>
  );
};
