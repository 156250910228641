import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { UserTable } from '@Components/tables';

export const UserOverviewPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('USER.PLURAL')}>
      <Card title={t('USER.PLURAL')}>
        <UserTable />
      </Card>
    </Page>
  );
};
