import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { ExportForm } from '@Components/forms';

export const ExportPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('EXPORT.TITLE')}>
      <Card title={t('EXPORT.TITLE')}>
        <ExportForm />
      </Card>
    </Page>
  );
};
