import i18n from 'i18next';

import { Routes } from '@socialbrothers/constants';
import { NotFoundPage } from '@socialbrothers/pages';

const routes: Routes = [
  {
    key: 'NotFoundPage',
    label: i18n.t('NOT_FOUND.LABEL'),
    icon: 'user',
    path: '/not-found',
    component: NotFoundPage,
  },
];

export default routes;
