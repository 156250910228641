import { Button } from '@socialbrothers/components/UI';
import { Color } from '@socialbrothers/constants';

import { ButtonActionProps } from './ButtonAction.props';

const ButtonAction = ({
  record,
  color = Color.PRIMARY,
  icon,
  label,
  onClick,
}: ButtonActionProps) => {
  return (
    <Button color={color} icon={icon} onClick={() => onClick(record)}>
      {label}
    </Button>
  );
};

export default ButtonAction;
