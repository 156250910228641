import { BaseModel } from '@socialbrothers/constants';

import { QuestionnaireType } from '@Services/Questionnaire';
import { IQuestionnaireQuestion } from '@Services/QuestionnaireQuestion';

export enum QuestionnaireSectionLinkableType {
  THEME = 'THEME',
  DOMAIN = 'DOMAIN',
  PERSONA_THEME = 'PERSONA_THEME',
}

export interface IQuestionnaireSection extends BaseModel {
  title: string;
  description: string;
  displayIntroduction: boolean;
  linkableType?: QuestionnaireSectionLinkableType;
  linkableId?: string;
  parentSectionId?: string;
  isScreeningSection: boolean;
  questions: IQuestionnaireQuestion[];
  questionnaireType: QuestionnaireType;
  childSections: IQuestionnaireSection[];
  concertoTestSlug?: string;
  concertoQuestionTableName?: string;
  image?: string | null;
}
