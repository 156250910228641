import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { Color, Operators } from '@socialbrothers/constants';
import { enumToOptions, modelToOptions, Yup } from '@socialbrothers/utils';

import { useThemes } from '@Hooks/useThemes';
import { useList } from '@Root/socialbrothers/hooks';
import { QuestionnaireType } from '@Services/Questionnaire';
import { IQuestionnaireSection, QuestionnaireSectionService } from '@Services/QuestionnaireSection';
import {
  ThemeResultConditionResult,
  ThemeResultConditionService,
  ThemeResultConditionType,
} from '@Services/ThemeResultCondition';

import { ThemeResultConditionFormValues } from '..';

export const ThemeResultConditionForm = () => {
  const { t } = useTranslation();
  const [type, setType] = useState();
  const queryClient = useQueryClient();
  const themes = useThemes();
  const sections = useList<IQuestionnaireSection>(QuestionnaireSectionService, {
    filtersJson: {
      'questionnaire.Type': [{ operator: Operators.IS, value: QuestionnaireType.SELFTEST }],
    },
  });
  const mutateCreate = useMutation(
    (values: ThemeResultConditionFormValues) => ThemeResultConditionService.create(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ThemeResultConditionService.endpoint]);
        toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('THEME_RESULT_CONDITION.SINGLE') }));
      },

      onError: () => {
        toast.error(
          t('GLOBAL.CREATED_UNSUCCESSFUL', {
            name: t('THEME_RESULT_CONDITION.SINGLE'),
          }),
        );
      },
    },
  );

  const handleCreate = useCallback(
    (values: ThemeResultConditionFormValues) => {
      return mutateCreate.mutateAsync(values);
    },
    [mutateCreate],
  );

  const validationSchema = Yup.object().shape({
    themeId: Yup.string().required(),
    result: Yup.string().required(),
    type: Yup.string().required(),
    min: Yup.number().when('type', {
      is: ThemeResultConditionType.SCORE_RANGE_AVERAGE || ThemeResultConditionType.SCORE_RANGE_SUM,
      then: (s) => s.min(0).required(),
    }),
    max: Yup.number().when('type', {
      is: ThemeResultConditionType.SCORE_RANGE_AVERAGE || ThemeResultConditionType.SCORE_RANGE_SUM,
      then: (s) => s.moreThan(Yup.ref('min')).required(),
    }),
    subscale1Id: Yup.string().when('type', {
      is: ThemeResultConditionType.SUBSCALE,
      then: (s) => s.required(),
    }),
    subscale1Result: Yup.string().when('type', {
      is: ThemeResultConditionType.SUBSCALE,
      then: (s) => s.required(),
    }),
    subscale2Id: Yup.string().when('type', {
      is: ThemeResultConditionType.SUBSCALE,
      then: (s) => s.required(),
    }),
    subscale2Result: Yup.string().when('type', {
      is: ThemeResultConditionType.SUBSCALE,
      then: (s) => s.required(),
    }),
  });

  return (
    <ButtonWithPopup
      withForm
      submit={{
        label: t('GLOBAL.CREATE'),
        onClick: handleCreate,
      }}
      validationSchema={validationSchema}
      popup={{
        title: t('GLOBAL.CREATE_MODEL', {
          name: t('THEME_RESULT_CONDITION.SINGLE'),
        }),
      }}
      button={{
        label: t('GLOBAL.CREATE_MODEL', {
          name: t('THEME_RESULT_CONDITION.SINGLE'),
        }),
        icon: 'plus',
        color: Color.SECONDARY,
      }}>
      {themes.data && (
        <Form.Layout.Field translationKey="THEME_RESULT_CONDITION.LABELS.THEME_ID">
          <Form.Input.Select
            name="themeId"
            options={modelToOptions(themes.data.results, 'title')}
          />
        </Form.Layout.Field>
      )}

      <Form.Layout.Field translationKey="THEME_RESULT_CONDITION.LABELS.RESULT">
        <Form.Input.Select
          name="result"
          options={enumToOptions(ThemeResultConditionResult, 'THEME_RESULT_CONDITION_RESULT')}
        />
      </Form.Layout.Field>

      <Form.Layout.Group label={t('THEME_RESULT_CONDITION.LABELS.TYPE')}>
        <Form.Layout.Field translationKey="THEME_RESULT_CONDITION.LABELS.TYPE" onChange={setType}>
          <Form.Input.Select
            name="type"
            options={enumToOptions(ThemeResultConditionType, 'THEME_RESULT_CONDITION_TYPE')}
          />
        </Form.Layout.Field>

        {(type === ThemeResultConditionType.SCORE_RANGE_AVERAGE ||
          type === ThemeResultConditionType.SCORE_RANGE_SUM) && (
          <>
            <Form.Layout.Field translationKey="THEME_RESULT_CONDITION.LABELS.MIN">
              <Form.Input.Number name="min" />
            </Form.Layout.Field>

            <Form.Layout.Field translationKey="THEME_RESULT_CONDITION.LABELS.MAX">
              <Form.Input.Number name="max" />
            </Form.Layout.Field>
          </>
        )}

        {type === ThemeResultConditionType.SUBSCALE && (
          <>
            {sections.data && (
              <Form.Layout.Field translationKey="THEME_RESULT_CONDITION.LABELS.SUBSCALE_ID1">
                <Form.Input.Select
                  name="subscale1Id"
                  options={modelToOptions(sections.data.results, 'title')}
                />
              </Form.Layout.Field>
            )}

            <Form.Layout.Field translationKey="THEME_RESULT_CONDITION.LABELS.RESULT">
              <Form.Input.Select
                name="subscale1Result"
                options={enumToOptions(ThemeResultConditionResult, 'THEME_RESULT_CONDITION_RESULT')}
              />
            </Form.Layout.Field>

            {sections.data && (
              <Form.Layout.Field translationKey="THEME_RESULT_CONDITION.LABELS.SUBSCALE_ID2">
                <Form.Input.Select
                  name="subscale2Id"
                  options={modelToOptions(sections.data.results, 'title')}
                />
              </Form.Layout.Field>
            )}

            <Form.Layout.Field translationKey="THEME_RESULT_CONDITION.LABELS.RESULT">
              <Form.Input.Select
                name="subscale2Result"
                options={enumToOptions(ThemeResultConditionResult, 'THEME_RESULT_CONDITION_RESULT')}
              />
            </Form.Layout.Field>
          </>
        )}
      </Form.Layout.Group>
    </ButtonWithPopup>
  );
};
