import CheckboxInput from '../CheckboxInput/CheckboxInput';
import { ToggleInputProps } from './ToggleInput.props';

const ToggleInput = ({ name, label, ...props }: ToggleInputProps) => {
  const options = [
    {
      key: 'true',
      value: label,
    },
  ];

  return <CheckboxInput name={name} options={options} {...props} />;
};

export default ToggleInput;
