import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Operators } from '@socialbrothers/constants';

import { CompanyDivisionService, ICompanyDivision } from '@Services/CompanyDivision';

import { CompanyDivisionTableProps } from './CompanyDivisionTable.props';

export const CompanyDisivionTable = ({ companyId, className }: CompanyDivisionTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<ICompanyDivision>
      searchable
      service={CompanyDivisionService}
      defaultFilters={{
        companyId: [
          {
            operator: Operators.IS,
            value: companyId,
          },
        ],
      }}
      className={className}>
      <Table.Field.Text sortable source="title" label={t('COMPANY_DIVISION.LABELS.TITLE')} />

      <Table.Field.Function
        source="domain"
        sortable
        width={1}
        label={t('COMPANY_DIVISION.LABELS.DOMAIN')}
        render={(division: ICompanyDivision) =>
          !!division.domain
            ? t('COMPANY_DIVISION.LABELS.DOMAIN_PREFIX') + division.domain
            : t('GLOBAL.NONE')
        }
      />

      <Table.Field.Text
        sortable
        width={1}
        source="licenseLimit"
        label={t('COMPANY_DIVISION.LABELS.LICENSE_LIMIT')}
      />

      <Table.Field.Text
        width={1}
        source="licenseCode"
        label={t('COMPANY_DIVISION.LABELS.LICENSE_CODE')}
      />

      <Table.Field.Date sortable width={1} source="createdAt" label={t('GLOBAL.CREATED_AT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: ICompanyDivision) => ({
          name: record.title,
        })}
        editConfig={(record: ICompanyDivision) => ({
          link: `/companies/${companyId}/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
