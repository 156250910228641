import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopupDelete } from '@socialbrothers/components/Containers';
import { Button, Icon } from '@socialbrothers/components/UI';

import { useQuestionnaireConfigurator } from '@Hooks/index';
import { IQuestionnaireSection, QuestionnaireSectionService } from '@Services/QuestionnaireSection';

import { QuestionForm } from '../QuestionForm/QuestionForm';
import { QuestionView } from '../QuestionView/QuestionView';
import { SectionForm } from '../SectionForm/SectionForm';
import styles from './SectionView.module.scss';
import { SectionViewMoveProps } from './SectionView.props';

export const SectionView = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const questionnaire = useQuestionnaireConfigurator();

  const mutateMove = useMutation(
    (values: SectionViewMoveProps) => {
      if (values.direction === 'up') {
        return QuestionnaireSectionService.moveUp(values.id);
      } else {
        return QuestionnaireSectionService.moveDown(values.id);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);
      },

      onError: () => {
        toast.error(t('QUESTIONNAIRE.CONFIGURATOR.SECTION.MOVE_FAILED'));
      },
    },
  );

  const handleMove = useCallback(
    (values: SectionViewMoveProps) => () => {
      mutateMove.mutateAsync(values);
    },
    [mutateMove],
  );

  const mutateDelete = useMutation(
    (id: string) => {
      return QuestionnaireSectionService.delete(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);
        toast.success(
          t('GLOBAL.DELETED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE') }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.DELETED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE'),
          }),
        );
      },
    },
  );

  const handleDelete = useCallback(
    (id: string) => async () => {
      return mutateDelete.mutateAsync(id);
    },
    [mutateDelete],
  );

  const getSection = useCallback(
    (section: IQuestionnaireSection, index: number, length: number) => {
      const hasSiblings = section.childSections.length > 0 || section.questions.length > 0;

      return (
        <div
          key={section.id}
          className={cn([
            styles.Sections__Item,
            styles.Section,
            {
              [styles['Section--Empty']]: !hasSiblings,
            },
          ])}>
          <div className={styles.Section__Header}>
            <h4 className={styles.Section__Title}>
              {t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE')} "{section.title}"{' '}
              {section.isScreeningSection && <Icon className="ml-xs" icon="radar" />}
            </h4>

            <div className={styles.Section__Buttons}>
              <Button
                onClick={handleMove({ id: section.id, direction: 'up' })}
                disabled={index === 0}
                icon="arrow-up"
                isLoading={mutateMove.isLoading}
              />

              <Button
                onClick={handleMove({ id: section.id, direction: 'down' })}
                disabled={index + 1 === length}
                icon="arrow-down"
                isLoading={mutateMove.isLoading}
              />

              <SectionForm section={section} />

              <ButtonWithPopupDelete name={section.title} onDelete={handleDelete(section.id)} />
            </div>
          </div>

          {section.childSections && (
            <div className={styles.Section__Body}>
              {section.childSections.map((childSection: IQuestionnaireSection, idx: number) => {
                return getSection(childSection, idx, section.childSections.length);
              })}

              {section.questions && (
                <QuestionView questions={section.questions} section={section} />
              )}

              {!hasSiblings && (
                <div className={styles.Section__EmptySection}>
                  {t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SECTION_EMPTY')}
                </div>
              )}
            </div>
          )}

          <div className={styles.Section__Footer}>
            {section.childSections.length === 0 && <QuestionForm section={section} />}

            {section.questions.length === 0 && <SectionForm parentSectionId={section.id} />}
          </div>
        </div>
      );
    },
    [handleDelete, handleMove, mutateMove.isLoading, t],
  );

  if (questionnaire.rootSections && questionnaire.rootSections.length > 0) {
    return (
      <div className={styles.Sections}>
        {questionnaire.rootSections.map((section: IQuestionnaireSection, index: number) => {
          return getSection(section, index, questionnaire.rootSections.length);
        })}
      </div>
    );
  } else {
    return (
      <div className={cn([styles.Sections, styles['Sections--None']])}>
        {t('QUESTIONNAIRE.CONFIGURATOR.SECTION.EMPTY')}
      </div>
    );
  }
};
