import { Service } from '@socialbrothers/services';

import { AppService } from '@Services/index';
import { getCurrentDateTime } from '@Utils/DateUtils';
import { downloadFile } from '@Utils/FileUtils';

class MiscServiceImplementation extends Service {
  exportCompanies = async () => {
    const response = await this.client.post(`${this.endpoint}/export/companies`);

    downloadFile(response.data, `export_${getCurrentDateTime()}.csv`);

    return response.data;
  };

  exportCompany = async (id: string) => {
    const response = await this.client.post(`${this.endpoint}/export/company/${id}`);

    downloadFile(response.data, `export_${getCurrentDateTime()}.csv`);

    return response.data;
  };
}

export const MiscService = new MiscServiceImplementation(AppService, '/admin/misc');
