import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { CompanyBrand, CompanySelfTestMethod, CompanyService, ICompany } from '@Services/Company';

import { CompanyTableProps } from './CompanyTable.props';

export const CompanyTable = ({ className }: CompanyTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<ICompany> searchable service={CompanyService} className={className}>
      <Table.Field.Text sortable source="title" label={t('COMPANY.LABELS.TITLE')} />

      <Table.Field.Enum
        sortable
        filterable
        width={1}
        source="brand"
        label={t('COMPANY.LABELS.BRAND')}
        name="COMPANY_BRAND"
        enumeration={CompanyBrand}
      />

      <Table.Field.Enum
        sortable
        width={1}
        source="selfTestMethod"
        label={t('COMPANY.LABELS.SELF_TEST_METHOD')}
        name="COMPANY_SELF_TEST_METHOD"
        enumeration={CompanySelfTestMethod}
      />

      <Table.Field.Boolean
        sortable
        width={1}
        source="isPrivate"
        label={t('COMPANY.LABELS.IS_PRIVATE')}
      />

      <Table.Field.Date sortable width={1} source="createdAt" label={t('GLOBAL.CREATED_AT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: ICompany) => ({
          name: record.title,
        })}
        editConfig={(record: ICompany) => ({
          link: `/companies/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
