import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { useResource } from '@socialbrothers/hooks';
import { Yup } from '@socialbrothers/utils';

import { IUser, UserService } from '@Services/User';

export const UserForm = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const user = useResource<IUser>(UserService, id);

  const validationSchema = Yup.object().shape({
    email: Yup.string().mail().required(),
    phoneNumber: Yup.string().phone().required(),
  });

  return (
    <Form.Resource
      service={UserService}
      validationSchema={validationSchema}
      label={t('USER.SINGLE')}
      id={id}>
      {!!user && (
        <Form.Layout.Field translationKey="USER.LABELS.ID">
          <Form.Input.Text name="id" disabled />
        </Form.Layout.Field>
      )}

      <Form.Layout.Field translationKey="USER.LABELS.FIRST_NAME">
        <Form.Input.Text name="firstName" disabled />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.LAST_NAME">
        <Form.Input.Text name="lastName" disabled />
      </Form.Layout.Field>

      <Form.Layout.Field
        translationKey="USER.LABELS.EMAIL"
        suffix={user.data?.emailConfirmed ? t('GLOBAL.CONFIRMED') : ''}>
        <Form.Input.Text name="email" />
      </Form.Layout.Field>

      <Form.Layout.Field
        translationKey="USER.LABELS.PHONE_NUMBER"
        suffix={user.data?.phoneNumberConfirmed ? t('GLOBAL.CONFIRMED') : ''}>
        <Form.Input.Text name="phoneNumber" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.SECURITY_QUESTION">
        <Form.Input.Text name="securityQuestion.question" disabled />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USER.LABELS.SECURITY_QUESTION_ANSWER">
        <Form.Input.Text name="securityQuestionAnswer" disabled />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
