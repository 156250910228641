import cn from 'classnames';

import styles from './Card.module.scss';
import { CardProps } from './Card.props';

const Card = ({ title, children, className, ...props }: CardProps) => {
  return (
    <div className={cn(styles.Card, className)} {...props}>
      <div className={styles.Header}>{title}</div>

      <div className={styles.Body}>{children}</div>
    </div>
  );
};

export default Card;
