import { useQuery } from 'react-query';

import { RequestConfig } from '@socialbrothers/constants';
import { ResourceInterface } from '@socialbrothers/services';

export function useList<T>(
  service: ResourceInterface,
  filtersJson?: RequestConfig<T>,
  enabled = true,
) {
  return useQuery([service.endpoint, JSON.stringify(filtersJson)], () => {
    if (enabled) {
      return service.getList(filtersJson as any);
    }
  });
}
