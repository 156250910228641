import styles from './Chip.module.scss';
import { ChipProps } from './Chip.props';

const Chip = ({ title, className, ...props }: ChipProps) => {
  return (
    <div className={styles.Chip} {...props}>
      {title}
    </div>
  );
};
export default Chip;
