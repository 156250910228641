import { useQuery } from 'react-query';

import { QuestionnaireQuestionService } from '@Services/QuestionnaireQuestion';

export const useConcertoQuestions = (tableName?: string) => {
  return useQuery(
    [QuestionnaireQuestionService.endpoint, tableName],
    () => QuestionnaireQuestionService.getConcertoQuestions(tableName || ''),
    {
      enabled: !!tableName,
      retry: false,
    },
  );
};
