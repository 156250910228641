import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import {
  IThemeResultCondition,
  ThemeResultConditionResult,
  ThemeResultConditionService,
  ThemeResultConditionType,
} from '@Services/ThemeResultCondition';

import { ThemeResultConditionTableProps } from './ThemeResultConditionTable.props';

export const ThemeResultConditionTable = ({ className }: ThemeResultConditionTableProps) => {
  const { t } = useTranslation();

  const getValue = useCallback(
    (condition: IThemeResultCondition) => {
      if (condition.type === ThemeResultConditionType.SCORE_RANGE_AVERAGE) {
        return t('THEME_RESULT_CONDITION.SCORE_RANGE_AVERAGE', {
          min: condition.min,
          max: condition.max,
        });
      }

      if (condition.type === ThemeResultConditionType.SCORE_RANGE_SUM) {
        return t('THEME_RESULT_CONDITION.SCORE_RANGE_SUM', {
          min: condition.min,
          max: condition.max,
        });
      }

      return t('THEME_RESULT_CONDITION.SUBSCALE', {
        subscale1: condition.subscale1?.title,
        result1: t(`ENUM.THEME_RESULT_CONDITION_RESULT.${condition.subscale1Result}` as any),
        subscale2: condition.subscale2?.title,
        result2: t(`ENUM.THEME_RESULT_CONDITION_RESULT.${condition.subscale2Result}` as any),
      });
    },
    [t],
  );

  return (
    <Table.Resource<IThemeResultCondition>
      searchable
      service={ThemeResultConditionService}
      className={className}>
      <Table.Field.Function
        filterable
        sortable
        source="theme.title"
        label={t('THEME_RESULT_CONDITION.LABELS.THEME')}
        render={(condition: IThemeResultCondition) => condition.theme?.title || ''}
      />

      <Table.Field.Enum
        sortable
        filterable
        source="result"
        label={t('THEME_RESULT_CONDITION.LABELS.RESULT')}
        name="THEME_RESULT_CONDITION_RESULT"
        enumeration={ThemeResultConditionResult}
      />

      <Table.Field.Enum
        filterable
        sortable
        source="type"
        label={t('THEME_RESULT_CONDITION.LABELS.TYPE')}
        name="THEME_RESULT_CONDITION_TYPE"
        enumeration={ThemeResultConditionType}
      />

      <Table.Field.Function
        source="value"
        label={t('THEME_RESULT_CONDITION.LABELS.VALUE')}
        render={getValue}
      />

      <Table.Field.ResourceAction
        deleteConfig={(record: IThemeResultCondition) => ({
          name: String(record.theme?.title),
        })}
      />
    </Table.Resource>
  );
};
