import { createContext, useContext } from 'react';

import { AppErrors } from '@Constants/errors';
import { IQuestionnaire } from '@Services/Questionnaire';

export const QuestionnaireConfiguratorContext = createContext<null | IQuestionnaire>(null);
export const QuestionnaireConfiguratorProvider = QuestionnaireConfiguratorContext.Provider;

export function useQuestionnaireConfigurator(): IQuestionnaire {
  const context = useContext(QuestionnaireConfiguratorContext);

  if (context === null) {
    throw new Error(AppErrors.QUESTIONNAIRE_CONFIGURATOR_CONTEXT);
  }

  return context;
}
