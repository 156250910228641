import { ListResponse } from '@socialbrothers/constants';
import { Service } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { ITheme } from './ThemeService.props';

class ThemeServiceImplementation extends Service {
  getThemes = async (): Promise<ListResponse<ITheme>> => {
    const response = await this.client.get<ListResponse<ITheme>>(this.endpoint, {
      perPage: 9999,
    });

    return response.data;
  };
}

export const ThemeService = new ThemeServiceImplementation(AppService, '/admin/themes');
