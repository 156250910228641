import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { EnumFieldProps } from './EnumField.props';

const EnumField = ({
  source,
  record,
  name,
  enumeration,
  className,
  hidden,
  ...props
}: EnumFieldProps) => {
  const { t } = useTranslation();
  const value = get(record, source);

  if (hidden) {
    return null;
  }

  return (
    <div className={className} {...props}>
      {t(`ENUM.${name}.${value}` as any)}
    </div>
  );
};

EnumField.displayName = 'EnumField';

export default EnumField;
