import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { Color } from '@socialbrothers/constants';
import { modelToOptions, Yup } from '@socialbrothers/utils';

import { useQuestionnaireConfigurator } from '@Hooks/index';
import { QuestionnaireAnswerService } from '@Services/QuestionnaireAnswer';
import { QuestionnaireQuestionType } from '@Services/QuestionnaireQuestion';
import { getQuestionsFromQuestionnaire, hasAnswers } from '@Utils/QuestionnaireUtils';

import { AnswerFormFormProps, AnswerFormProps } from './AnswerForm.props';

export const AnswerForm = ({ answer, question }: AnswerFormProps) => {
  const { t } = useTranslation();
  const [hasNextQuestion, setHasNextQuestion] = useState();
  const queryClient = useQueryClient();
  const questionnaire = useQuestionnaireConfigurator();

  const hasDescription = [QuestionnaireQuestionType.SLIDER_STEP].includes(question.type);

  const hasConditionalLogic = hasAnswers(question.type);

  const mutateCreate = useMutation(
    (values: AnswerFormFormProps) =>
      QuestionnaireAnswerService.create({
        ...values,
        questionnaireQuestionId: question.id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);

        toast.success(
          t('GLOBAL.CREATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.SINGLE') }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.CREATED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.SINGLE'),
          }),
        );
      },
    },
  );

  const mutateUpdate = useMutation(
    (values: AnswerFormFormProps) =>
      QuestionnaireAnswerService.update(answer ? answer.id : '', values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);

        toast.success(
          t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.SINGLE') }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.UPDATED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.SINGLE'),
          }),
        );
      },
    },
  );

  const validationSchema = Yup.object().shape({
    text: Yup.string().required(),
    description: hasDescription ? Yup.string().required() : Yup.string(),
    score: Yup.string().min(0),
    nextQuestionId: hasNextQuestion && hasConditionalLogic ? Yup.string().required() : Yup.string(),
  });

  const questions = useMemo(() => {
    return getQuestionsFromQuestionnaire(questionnaire).filter((q) => q.id !== question.id);
  }, [questionnaire, question]);

  return (
    <ButtonWithPopup
      withForm
      validationSchema={validationSchema}
      initialValues={{
        ...answer,
        hasNextQuestionId: !!answer?.nextQuestionId,
      }}
      button={
        answer
          ? { link: true, icon: 'pencil' }
          : {
              color: Color.TERTIARY,
              icon: 'plus',
              label: t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.CREATE'),
            }
      }
      submit={{
        label: answer ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
        onClick: (values: AnswerFormFormProps) => {
          return answer ? mutateUpdate.mutateAsync(values) : mutateCreate.mutateAsync(values);
        },
      }}
      popup={{
        title: answer
          ? t('GLOBAL.UPDATE_MODEL', {
              name: answer.text,
            })
          : t('GLOBAL.CREATE_MODEL', {
              name: t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.SINGLE'),
            }),
      }}>
      <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.ANSWER.LABELS.TEXT">
        <Form.Input.Text name="text" />
      </Form.Layout.Field>

      {hasDescription && (
        <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.ANSWER.LABELS.DESCRIPTION">
          <Form.Input.Text name="description" />
        </Form.Layout.Field>
      )}

      <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.ANSWER.LABELS.SCORE">
        <Form.Input.Number name="score" min={0} />
      </Form.Layout.Field>

      {hasConditionalLogic && (
        <>
          <Form.Layout.Group label={t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.LABELS.CONDITIONAL')}>
            <Form.Layout.Field
              translationKey="QUESTIONNAIRE.CONFIGURATOR.ANSWER.LABELS.HAS_NEXT_QUESTION_ID"
              onChange={setHasNextQuestion}>
              <Form.Input.Toggle
                name="hasNextQuestionId"
                label={t(
                  'QUESTIONNAIRE.CONFIGURATOR.ANSWER.LABELS.HAS_NEXT_QUESTION_ID_DESCRIPTION',
                )}
              />
            </Form.Layout.Field>

            {hasNextQuestion && (
              <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.ANSWER.LABELS.NEXT_QUESTION_ID">
                <Form.Input.Select
                  name="nextQuestionId"
                  options={modelToOptions(questions, 'title')}
                />
              </Form.Layout.Field>
            )}
          </Form.Layout.Group>
        </>
      )}
    </ButtonWithPopup>
  );
};
