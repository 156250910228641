import { useEffect, useState } from 'react';

export const useCountdown = (countdown: number) => {
  const [count, setCount] = useState(0);
  const [startState, setStart] = useState(false);

  const start = () => {
    setCount(countdown);
    setStart(true);
  };

  const reset = () => {
    setStart(false);
  };

  useEffect(() => {
    if (startState) {
      const interval = setInterval(() => {
        setCount((oldCount) => {
          return oldCount - 1;
        });

        if (count === 1) {
          reset();
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [startState, count]);

  return {
    start,
    reset,
    count,
  };
};
