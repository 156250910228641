import { BaseModel } from '@socialbrothers/constants';

import { IQuestionnaireSection } from '@Services/QuestionnaireSection';

export enum QuestionnaireType {
  PERSONA = 'PERSONA',
  SELFTEST = 'SELFTEST',
}

export interface IQuestionnaire extends BaseModel {
  description: string;
  label: string;
  title: string;
  type: QuestionnaireType;
  rootSections: IQuestionnaireSection[];
}
