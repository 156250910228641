import { Role } from '@socialbrothers/constants';
import { Header, Routes, Sidebar } from '@socialbrothers/constants/interfaces';
import { getRouteByKey, hasRole } from '@socialbrothers/utils';

import {
  AccountPage,
  AdminDetailPage,
  AdminOverviewPage,
  CompanyDetailPage,
  CompanyDivisionDetailPage,
  CompanyDivisionUserDetailPage,
  CompanyOverviewPage,
  ExportPage,
  QuestionnairePersonaPage,
  QuestionnaireSelftestPage,
  SubscaleOverviewPage,
  ThemeResultConditionOverviewPage,
  UserOverviewPage,
} from '@Pages/index';
import i18n from '@Root/i18n';

export const routes: Routes = [
  {
    key: 'CompanyDivisionUserDetailPage',
    label: i18n.t('USER.SINGLE'),

    path: '/companies/:companyId/:divisionId/:id',
    component: CompanyDivisionUserDetailPage,
    exact: true,
    hidden: hasRole(Role.Admin) === false,
  },
  {
    key: 'CompanyDivisionDetailPage',
    label: i18n.t('COMPANY_DIVISION.SINGLE'),

    path: '/companies/:companyId/:id',
    component: CompanyDivisionDetailPage,
    exact: true,
    hidden: hasRole(Role.Admin) === false,
  },
  {
    key: 'CompanyDetailPage',
    label: i18n.t('COMPANY.SINGLE'),

    path: '/companies/:id',
    component: CompanyDetailPage,
    exact: true,
    hidden: hasRole(Role.Admin) === false,
  },
  {
    key: 'CompanyOverviewPage',
    label: i18n.t('COMPANY.PLURAL'),
    icon: 'building',

    path: '/companies',
    component: CompanyOverviewPage,
    exact: true,
    hidden: hasRole(Role.Admin) === false,
  },
  {
    key: 'UserOverviewPage',
    label: i18n.t('USER.PLURAL'),
    icon: 'users',

    path: '/users',
    component: UserOverviewPage,
    exact: true,
    hidden: hasRole(Role.Admin) === false,
  },
  {
    key: 'AdminDetailPage',
    label: i18n.t('ADMIN.SINGLE'),

    path: '/administrators/:id',
    component: AdminDetailPage,
    exact: true,
    hidden: hasRole(Role.Admin) === false,
  },
  {
    key: 'AdminOverviewPage',
    label: i18n.t('ADMIN.PLURAL'),

    path: '/administrators',
    component: AdminOverviewPage,
    exact: true,
    hidden: hasRole(Role.Admin) === false,
  },
  {
    key: 'ThemeResultConditionOverviewPage',
    label: i18n.t('THEME_RESULT_CONDITION.PLURAL'),

    path: '/selftest/themeresultconditions',
    component: ThemeResultConditionOverviewPage,
    exact: true,
    hidden: hasRole(Role.RND) === false,
  },
  {
    key: 'SubscaleOverviewPage',
    label: i18n.t('SUBSCALE.PLURAL'),

    path: '/selftest/subscales',
    component: SubscaleOverviewPage,
    exact: true,
    hidden: hasRole(Role.RND) === false,
  },
  {
    key: 'QuestionnaireSelftestPage',
    label: i18n.t('QUESTIONNAIRE.TYPE.SELFTEST'),

    path: '/selftest',
    component: QuestionnaireSelftestPage,
    exact: true,
    hidden: hasRole(Role.RND) === false,
  },
  {
    key: 'QuestionnairePersonaPage',
    label: i18n.t('QUESTIONNAIRE.TYPE.PERSONA'),
    icon: 'person-sign',

    path: '/persona',
    component: QuestionnairePersonaPage,
    exact: true,
    hidden: hasRole(Role.RND) === false,
  },
  {
    key: 'ExportPage',
    label: i18n.t('EXPORT.TITLE'),
    icon: 'person-sign',

    path: '/export',
    component: ExportPage,
    exact: true,
    hidden: hasRole(Role.RND) === false,
  },
  {
    key: 'AccountPage',
    exact: true,
    path: '/account',
    component: AccountPage,
    icon: 'user',
    label: i18n.t('ACCOUNT.TITLE'),
  },
];

export const sidebar: Sidebar = [
  {
    title: i18n.t('GLOBAL.GENERAL'),
    routes: [
      getRouteByKey(routes, 'CompanyOverviewPage'),
      getRouteByKey(routes, 'UserOverviewPage'),
    ],
  },
  {
    title: i18n.t('QUESTIONNAIRE.PLURAL'),
    routes: [
      {
        ...getRouteByKey(routes, 'QuestionnaireSelftestPage'),
        children: [
          getRouteByKey(routes, 'ThemeResultConditionOverviewPage'),
          getRouteByKey(routes, 'SubscaleOverviewPage'),
        ],
      },
      getRouteByKey(routes, 'QuestionnairePersonaPage'),
      getRouteByKey(routes, 'ExportPage'),
    ],
  },
];

export const header: Header = [
  getRouteByKey(routes, 'AccountPage'),
  getRouteByKey(routes, 'AdminOverviewPage'),
];
