import { BaseModel } from '@socialbrothers/constants';

export interface ICompany extends BaseModel {
  brand: CompanyBrand;
  domain: string;
  licenseCode: string;
  licenseLimit: number;
  assignRandomSelfTestMethods: boolean;
  selfTestMethod: CompanySelfTestMethod;
  title: string;
  size: CompanySize;
  isPrivate: string;
}

export enum CompanyBrand {
  ARBONED = 'ARBONED',
  MENSELY = 'MENSELY',
  KOM_VERDER = 'KOM_VERDER',
  HUMAN_CAPITAL_CARE = 'HUMAN_CAPITAL_CARE',
}

export enum CompanySelfTestMethod {
  REGULAR = 'REGULAR',
  ADAPTIVE = 'ADAPTIVE',
  PHASED = 'PHASED',
}

export enum CompanyType {
  INTEGRAL = 'INTEGRAL',
  PARALLEL = 'PARALLEL',
  ARBO = 'ARBO',
}

export enum CompanySize {
  '1-50' = '1-50',
  '50-100' = '50-100',
  '100-250' = '100-250',
  '250-1000' = '250-1000',
  '1000+' = '1000+',
}
