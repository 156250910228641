import {
  BaseModel,
  Filter,
  ListResponse,
  Operators,
  RequestConfig,
} from '@socialbrothers/constants';

import { getCurrentDateTime } from '@Utils/DateUtils';
import { downloadFile } from '@Utils/FileUtils';

import { Service } from './Service';

export interface ResourceInterface {
  endpoint: string;
  getOne: (id: string, filtersJson?: Filter<any>) => Promise<any>;
  getList: (
    config?: Filter<any>,
    extra?: { [key: string]: string | number },
  ) => Promise<ListResponse<any>>;
  export: (config?: Filter<any>, extra?: { [key: string]: string | number }) => Promise<void>;
  create: (values: any) => Promise<any>;
  update: (id: string, values: any) => Promise<any>;
  delete: (id: string) => Promise<any>;
  validate: (field: string, value: any) => Promise<boolean>;
}
class ResourceService<T extends BaseModel> extends Service implements ResourceInterface {
  getOne = async (id: string) => {
    const response = await this.client.get<T>(`${this.endpoint}/${id}`);

    return response.data;
  };

  getList = async (config?: RequestConfig<T>, extra?: any) => {
    const params = {
      ...config,
      ...extra,
    };

    if (config?.filtersJson) {
      params.filtersJson = config.filtersJson;
    }

    const response = await this.client.get<ListResponse<T>>(this.endpoint, params);

    return response.data;
  };

  export = async (config?: RequestConfig<T>, extra?: any) => {
    const params = {
      ...config,
      ...extra,
      filtersJson: config?.filtersJson || null,
      export: 1,
    };

    const response = await this.client.get<ListResponse<T[]>>(this.endpoint, params);

    downloadFile(response.data, `export_${getCurrentDateTime()}.csv`);
  };

  getMany = (ids: string[], filtersJson?: RequestConfig<T>) => {
    return this.getList({
      ...filtersJson,
      filtersJson: {
        ...filtersJson?.filtersJson,
        id: {
          operator: Operators.IS,
          value: ids,
        },
      },
    } as any);
  };

  create = async (data: Partial<T>) => {
    const response = await this.client.post<T>(this.endpoint, data);

    return response.data;
  };

  update = async (id: string, data: Partial<T>) => {
    data.id = id;
    const response = await this.client.put<T>(`${this.endpoint}/${id}`, data);

    return response.data;
  };

  delete = async (id: string) => {
    const response = await this.client.delete(`${this.endpoint}/${id}`);

    return response.data;
  };

  deleteMany = (ids: string[]) => {
    return Promise.all(
      ids.map((id) => {
        return this.delete(id);
      }),
    );
  };

  deleteFile = async (id: string, field: string) => {
    const response = await this.client.delete(`${this.endpoint}/${id}/${field}`);

    return response.data;
  };

  validate = async (field: string, value: any) => {
    const params = {
      field: field,
      value: value,
    };

    const response = await this.client.post<{ isValid: boolean }>(
      `${this.endpoint}/validate`,
      params,
    );

    return response.data.isValid;
  };
}

export default ResourceService;
