import { useTranslation } from 'react-i18next';

import { LoginForm } from '@socialbrothers/components/Forms';
import { AuthWrapper } from '@socialbrothers/components/Layout';
import { useTheme } from '@socialbrothers/hooks';

interface Props {}

const LoginPage = ({ ...props }: Props) => {
  const { config } = useTheme();
  const { t } = useTranslation();

  const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
  };

  return (
    <AuthWrapper
      imageUri={config.loginImage}
      title={t('AUTHENTICATION.LOGIN.PAGE_TITLE')}
      {...props}>
      <LoginForm initialValues={initialValues} />
    </AuthWrapper>
  );
};

export default LoginPage;
