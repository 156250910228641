import { ResourceService } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { IQuestionnaireSection } from './QuestionnaireSectionService.props';

export class QuestionnaireSectionServiceImplementation extends ResourceService<IQuestionnaireSection> {
  moveUp = async (id: string) => {
    const response = await this.client.post<IQuestionnaireSection>(`${this.endpoint}/${id}/moveup`);

    return response.data;
  };

  moveDown = async (id: string) => {
    const response = await this.client.post<IQuestionnaireSection>(
      `${this.endpoint}/${id}/movedown`,
    );

    return response.data;
  };
}

export const QuestionnaireSectionService = new QuestionnaireSectionServiceImplementation(
  AppService,
  '/admin/questionnairesections',
);
