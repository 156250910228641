import { YupFileExtensionStatus } from '../constants';

export const getFileURL = (filename: string) => {
  return `${process.env.REACT_APP_API_URL}/uploads/${filename}`;
};

export const isFileImage = (
  path: string,
): Promise<{ path: string; status: YupFileExtensionStatus }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: YupFileExtensionStatus.OK });
    img.onerror = () => resolve({ path, status: YupFileExtensionStatus.ERROR });

    img.src = path;
  });
};
