import cn from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopupDelete } from '@socialbrothers/components/Containers';
import { Button, Icon } from '@socialbrothers/components/UI';
import { formatNumberWithLeadingZero } from '@socialbrothers/utils';

import { useQuestionnaireConfigurator } from '@Hooks/index';
import { IQuestionnaireAnswer, QuestionnaireAnswerService } from '@Services/QuestionnaireAnswer';

import { AnswerForm } from '../AnswerForm/AnswerForm';
import styles from './AnswerView.module.scss';
import { AnswerViewMoveProps, AnswerViewProps } from './AnswerView.props';

export const AnswerView = ({ answers, question }: AnswerViewProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const questionnaire = useQuestionnaireConfigurator();

  const mutateMove = useMutation(
    (values: AnswerViewMoveProps) => {
      if (values.direction === 'up') {
        return QuestionnaireAnswerService.moveUp(values.id);
      } else {
        return QuestionnaireAnswerService.moveDown(values.id);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);
      },

      onError: () => {
        toast.error(t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.MOVE_FAILED'));
      },
    },
  );

  const handleMove = useCallback(
    (values: AnswerViewMoveProps) => () => {
      mutateMove.mutateAsync(values);
    },
    [mutateMove],
  );

  const mutateDelete = useMutation(
    (id: string) => {
      return QuestionnaireAnswerService.delete(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);
        toast.success(
          t('GLOBAL.DELETED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.SINGLE') }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.DELETED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.SINGLE'),
          }),
        );
      },
    },
  );

  const handleDelete = useCallback(
    (id: string) => async () => {
      return mutateDelete.mutateAsync(id);
    },
    [mutateDelete],
  );

  const getAnswer = useCallback(
    (answer: IQuestionnaireAnswer, index: number, length: number) => {
      return (
        <div key={answer.id} className={styles.Answers__Item}>
          <div className={styles.Answer__Header}>
            <div className={styles.Answer__Title}>
              {t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.TITLE', {
                answer: answer.text,
                index: formatNumberWithLeadingZero(index + 1),
              })}

              {!!answer.nextQuestion && <Icon className="ml-xs" icon="external-link-alt" />}
            </div>

            <div className={styles.Answer__Buttons}>
              <Button
                link
                onClick={handleMove({ id: answer.id, direction: 'up' })}
                disabled={index === 0}
                icon="arrow-up"
                isLoading={mutateMove.isLoading}
              />

              <Button
                link
                onClick={handleMove({ id: answer.id, direction: 'down' })}
                disabled={index + 1 === length}
                icon="arrow-down"
                isLoading={mutateMove.isLoading}
              />

              <AnswerForm answer={answer} question={question} />

              <ButtonWithPopupDelete link name={answer.text} onDelete={handleDelete(answer.id)} />
            </div>
          </div>
        </div>
      );
    },
    [handleDelete, handleMove, mutateMove.isLoading, question, t],
  );

  if (answers.length > 0) {
    return (
      <div className={styles.Answers}>
        {answers.map((answer: IQuestionnaireAnswer, index: number) => {
          return getAnswer(answer, index, answers.length);
        })}
      </div>
    );
  } else {
    return (
      <div className={cn([styles.Answers, styles['Answers--None']])}>
        {t('QUESTIONNAIRE.CONFIGURATOR.ANSWER.EMPTY')}
      </div>
    );
  }
};
