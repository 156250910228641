import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { Color, Operators } from '@socialbrothers/constants';
import { useList } from '@socialbrothers/hooks';
import { enumToOptions, modelToOptions, Yup } from '@socialbrothers/utils';

import { QuestionnaireType } from '@Services/Questionnaire';
import { IQuestionnaireSection, QuestionnaireSectionService } from '@Services/QuestionnaireSection';
import { SubscaleService } from '@Services/Subscale';
import { ThemeResultConditionResult } from '@Services/ThemeResultCondition';

import { SubscaleFormValues } from '..';

export const SubscaleForm = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const sections = useList<IQuestionnaireSection>(QuestionnaireSectionService, {
    filtersJson: {
      'questionnaire.Type': [{ operator: Operators.IS, value: QuestionnaireType.SELFTEST }],
    },
  });

  const mutateCreate = useMutation((values: SubscaleFormValues) => SubscaleService.create(values), {
    onSuccess: () => {
      queryClient.invalidateQueries([SubscaleService.endpoint]);
      toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('SUBSCALE.SINGLE') }));
    },

    onError: () => {
      toast.error(
        t('GLOBAL.CREATED_UNSUCCESSFUL', {
          name: t('SUBSCALE.SINGLE'),
        }),
      );
    },
  });

  const handleCreate = useCallback(
    (values: SubscaleFormValues) => {
      return mutateCreate.mutateAsync(values);
    },
    [mutateCreate],
  );

  const validationSchema = Yup.object().shape({
    questionnaireSectionId: Yup.string().required(),
    result: Yup.string().required(),
    min: Yup.number().required(),
    max: Yup.number().required(),
  });

  return (
    <ButtonWithPopup
      withForm
      submit={{
        label: t('GLOBAL.CREATE'),
        onClick: handleCreate,
      }}
      validationSchema={validationSchema}
      popup={{
        title: t('GLOBAL.CREATE_MODEL', {
          name: t('SUBSCALE.SINGLE'),
        }),
      }}
      button={{
        label: t('GLOBAL.CREATE_MODEL', {
          name: t('SUBSCALE.SINGLE'),
        }),
        icon: 'plus',
        color: Color.SECONDARY,
      }}>
      {sections.data && (
        <Form.Layout.Field translationKey="SUBSCALE.LABELS.QUESTIONNAIRE_SECTION_ID">
          <Form.Input.Select
            name="questionnaireSectionId"
            options={modelToOptions(sections.data.results, 'title')}
          />
        </Form.Layout.Field>
      )}

      <Form.Layout.Field translationKey="SUBSCALE.LABELS.RESULT">
        <Form.Input.Select
          name="result"
          options={enumToOptions(ThemeResultConditionResult, 'THEME_RESULT_CONDITION_RESULT')}
        />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="SUBSCALE.LABELS.MIN">
        <Form.Input.Number name="min" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="SUBSCALE.LABELS.MIN">
        <Form.Input.Number name="max" />
      </Form.Layout.Field>
    </ButtonWithPopup>
  );
};
