import { ListResponse } from '@socialbrothers/constants';
import { Service } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { IPersonaTheme } from './PersonaThemeService.props';

class PersonaThemeServiceImplementation extends Service {
  getPersonaThemes = async (): Promise<ListResponse<IPersonaTheme>> => {
    const response = await this.client.get<ListResponse<IPersonaTheme>>(this.endpoint, {
      perPage: 9999,
    });

    return response.data;
  };
}

export const PersonaThemeService = new PersonaThemeServiceImplementation(
  AppService,
  '/admin/personathemes',
);
