import ACCOUNT from './account.json';
import ADMIN from './admin.json';
import COMPANY from './company.json';
import COMPANY_DIVISION from './companyDivision.json';
import ENUM from './enum.json';
import EXPORT from './export.json';
import GLOBAL from './global.json';
import QUESTIONNAIRE from './questionnaire.json';
import SUBSCALE from './subscale.json';
import THEME_RESULT_CONDITION from './themeResultCondition.json';
import USER from './user.json';
import USERS from './users.json';

const data = {
  translation: {
    ACCOUNT,
    GLOBAL,
    USERS,
    QUESTIONNAIRE,
    ENUM,
    COMPANY,
    COMPANY_DIVISION,
    SUBSCALE,
    THEME_RESULT_CONDITION,
    ADMIN,
    USER,
    EXPORT,
  },
};

export default data;
