import { QuestionnaireConfiguratorProvider, useQuestionnaire } from '@Hooks/index';

import { SectionForm } from './internal/SectionForm/SectionForm';
import { SectionView } from './internal/SectionView/SectionView';
import { QuestionnaireConfiguratorProps } from './QuestionnaireConfigurator.props';

export const QuestionnaireConfigurator = ({ type }: QuestionnaireConfiguratorProps) => {
  const { data } = useQuestionnaire(type);

  if (data) {
    return (
      <QuestionnaireConfiguratorProvider value={data}>
        <SectionView />
        <SectionForm />
      </QuestionnaireConfiguratorProvider>
    );
  }

  return null;
};
