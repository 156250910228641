import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { ISubscale, SubscaleService } from '@Services/Subscale';
import { ThemeResultConditionResult } from '@Services/ThemeResultCondition';

import { SubscaleTableProps } from './SubscaleTable.props';

export const SubscaleTable = ({ className }: SubscaleTableProps) => {
  const { t } = useTranslation();

  const getValue = useCallback(
    (condition: ISubscale) => {
      return t('SUBSCALE.SCORE_RANGE_AVERAGE', {
        min: condition.min,
        max: condition.max,
      });
    },
    [t],
  );

  return (
    <Table.Resource<ISubscale> searchable service={SubscaleService} className={className}>
      <Table.Field.Function
        filterable
        sortable
        source="questionnaireSection.title"
        label={t('SUBSCALE.LABELS.QUESTIONNAIRE_SECTION_ID')}
        render={(subscale: ISubscale) => subscale.questionnaireSection?.title || ''}
      />

      <Table.Field.Enum
        sortable
        filterable
        source="result"
        label={t('SUBSCALE.LABELS.RESULT')}
        name="THEME_RESULT_CONDITION_RESULT"
        enumeration={ThemeResultConditionResult}
      />

      <Table.Field.Function source="value" label={t('SUBSCALE.LABELS.VALUE')} render={getValue} />

      <Table.Field.ResourceAction
        deleteConfig={(record: ISubscale) => ({
          name: String(record.questionnaireSection?.title),
        })}
      />
    </Table.Resource>
  );
};
