import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Icon } from '@socialbrothers/components/UI';
import { Operators } from '@socialbrothers/constants';

import { IUser, UserService } from '@Services/User';

import { CompanyDivisionUserTableProps } from './CompanyDivisionUserTable.props';

export const CompanyDivisionUserTable = ({
  divisionId,
  className,
}: CompanyDivisionUserTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IUser>
      searchable
      service={UserService}
      defaultFilters={{
        'companyDivision.id': [{ operator: Operators.IS, value: String(divisionId) }],
      }}
      className={className}>
      <Table.Field.Function
        sortable
        filterable
        source="firstName"
        label={t('USER.LABELS.NAME')}
        render={(user: IUser) => `${user.firstName} ${user.lastName}`}
      />

      <Table.Field.Function
        sortable
        source="email"
        label={t('USER.LABELS.EMAIL')}
        render={(user: IUser) => {
          return (
            <>
              <Icon
                className={cn('mr-xs', {
                  'color-success': user.emailConfirmed,
                  'color-danger': !user.emailConfirmed,
                })}
                icon={`${user.emailConfirmed ? 'check-square' : 'times-square'}`}
              />
              {user.email}
            </>
          );
        }}
      />

      <Table.Field.Function
        sortable
        source="phonenumber"
        label={t('USER.LABELS.PHONE_NUMBER')}
        render={(user: IUser) => {
          return (
            <>
              <Icon
                className={cn('mr-xs', {
                  'color-success': user.phoneNumberConfirmed,
                  'color-danger': !user.phoneNumberConfirmed,
                })}
                icon={`${user.phoneNumberConfirmed ? 'check-square' : 'times-square'}`}
              />
              {!!user.phoneNumber ? user.phoneNumber : t('GLOBAL.UNKNOWN')}
            </>
          );
        }}
      />

      <Table.Field.Date
        showTime
        sortable
        source="lastLoginAt"
        label={t('USER.LABELS.LAST_LOGIN_AT')}
      />

      <Table.Field.Date showTime sortable source="createdAt" label={t('GLOBAL.CREATED_AT')} />

      <Table.Field.ResourceAction
        deleteConfig={(record: IUser) => ({
          name: `${record.firstName} ${record.lastName}`,
        })}
        editConfig={(record: IUser) => ({
          link: `/companies/${record.companyDivision.company.id}/${record.companyDivision.id}/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
