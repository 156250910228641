import { ResourceService } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { ICompanyDivision } from './CompanyDivisionService.props';

export class CompanyDivisionServiceImplementation extends ResourceService<ICompanyDivision> {}

export const CompanyDivisionService = new CompanyDivisionServiceImplementation(
  AppService,
  '/admin/companydivisions',
);
