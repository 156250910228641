import { Role } from '@socialbrothers/constants';
import { Storage } from '@socialbrothers/helpers';

export const hasRole = (role: Role | Role[]) => {
  if (Array.isArray(role)) {
    role.some((r) => Storage.getAccessTokenRole().includes(r));
  } else {
    return Storage.getAccessTokenRole().includes(role);
  }
};
