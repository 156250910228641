import { get } from 'lodash';

import { Fields } from '@socialbrothers/constants';

import { RelationFieldProps } from './RelationField.props';

function RelationField<T>({
  source,
  record,
  namePath,
  renderName,
  className,
  options,
  hidden,
  ...props
}: RelationFieldProps<T>) {
  const values = get(record, source);

  if (hidden) {
    return null;
  }

  const getName = (item: T) => {
    if (renderName) {
      return renderName(item);
    }

    if (namePath) {
      return get(item, namePath);
    }

    return '';
  };

  return (
    <div className={className} {...props}>
      {values.map(getName).join(', ')}
    </div>
  );
}

RelationField.displayName = Fields.RELATION_FIELD;

export default RelationField;
