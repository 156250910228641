import { useTranslation } from 'react-i18next';

import { ButtonWithPopup } from '@socialbrothers/components/Containers';
import { Color } from '@socialbrothers/constants';

import { ButtonWithPopupDeleteProps } from './ButtonWithPopupDelete.props';

export const ButtonWithPopupDelete = ({ name, link, onDelete }: ButtonWithPopupDeleteProps) => {
  const { t } = useTranslation();

  return (
    <ButtonWithPopup
      button={{
        link: link,
        icon: 'trash-alt',
        color: Color.DANGER,
      }}
      submit={{
        label: t('GLOBAL.DELETE'),
        color: Color.DANGER,
        onClick: onDelete,
      }}
      popup={{
        title: t('RESOURCE.DELETE.MODAL.TITLE', { name: name }),
      }}>
      <p>
        {t('RESOURCE.DELETE.MODAL.DESCRIPTION', {
          name: name,
        })}
      </p>
    </ButtonWithPopup>
  );
};
