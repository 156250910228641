import { Service } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { IQuestionnaire, QuestionnaireType } from './QuestionnaireService.props';

export class QuestionnaireServiceImplementation extends Service {
  getQuestionnaire = async (type: QuestionnaireType) => {
    const response = await this.client.get<IQuestionnaire>(
      `${this.endpoint}/questionnaires/bytype/${type}`,
    );

    return response.data;
  };
}

export const QuestionnaireService = new QuestionnaireServiceImplementation(AppService, '/admin');
