import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useBreadcrumbs, useResource } from '@socialbrothers/hooks';

import { UserForm } from '@Components/forms';
import { routes } from '@Routes/routes';
import { CompanyService, ICompany } from '@Services/Company';
import { CompanyDivisionService, ICompanyDivision } from '@Services/CompanyDivision';
import { IUser, UserService } from '@Services/User';

export const CompanyDivisionUserDetailPage = () => {
  const { t } = useTranslation();
  const { companyId, divisionId, id } =
    useParams<{ companyId: string; divisionId: string; id: string }>();

  const company = useResource<ICompany>(CompanyService, companyId);
  const division = useResource<ICompanyDivision>(CompanyDivisionService, divisionId);
  const user = useResource<IUser>(UserService, id);

  const label = user.data ? `${user.data.firstName} ${user.data.lastName}` : t('USER.SINGLE');
  let items = useBreadcrumbs(routes, label);

  if (items[1] && items[1].key === 'CompanyDetailPage' && company.data) {
    items[1].label = company.data.title;
  }

  if (items[2] && items[2].key === 'CompanyDivisionDetailPage' && division.data) {
    items[2].label = division.data.title;
  }

  return (
    <Page title={label} breadcrumbs={items}>
      <Card title={label}>
        <UserForm />
      </Card>
    </Page>
  );
};
