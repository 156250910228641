import { ListResponse } from '@socialbrothers/constants';
import { Service } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { IDomain } from './DomainService.props';

class DomainServiceImplementation extends Service {
  getDomains = async (): Promise<ListResponse<IDomain>> => {
    const response = await this.client.get<ListResponse<IDomain>>(this.endpoint, {
      perPage: 9999,
    });

    return response.data;
  };
}

export const DomainService = new DomainServiceImplementation(AppService, '/admin/domains');
