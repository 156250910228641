import { BaseModel } from '@socialbrothers/constants';

import { IPersona } from '@Services/Persona';
import { IQuestionnaireAnswer } from '@Services/QuestionnaireAnswer';

export enum QuestionnaireQuestionType {
  CAROUSEL = 'CAROUSEL',
  DROPDOWN = 'DROPDOWN',
  SLIDER = 'SLIDER',
  SLIDER_STEP = 'SLIDER_STEP',
  SLIDER_EMOJI = 'SLIDER_EMOJI',
  RADIO = 'RADIO',
}

export interface IQuestionnaireQuestionPersonaScore extends BaseModel {
  personaId: string;
  persona: IPersona;
  score: number;
  weighsDouble: boolean;
}

export interface IConcertoQuestion extends BaseModel {
  trait: string;
  question: string;
}

export interface IQuestionnaireQuestion extends BaseModel {
  answers: IQuestionnaireAnswer[];
  title: string;
  description: string;
  headerTitle: string;
  headerDescription: string;
  questionnaireSectionId: string;
  type: QuestionnaireQuestionType;
  scaleMinDescription?: string; // QuestionnaireSectionType.Slider
  scaleMaxDescription?: string; // QuestionnaireSectionType.Slider
  label?: string;
  concertoQuestionId?: number;
  personaScores: IQuestionnaireQuestionPersonaScore[];
}
