import { ForgotPasswordPage, LoginPage, ResetPasswordPage } from '@socialbrothers/pages';

const routes = [
  {
    key: 'LoginPage',
    label: 'Login',
    icon: 'user',

    path: '/',
    component: LoginPage,
    exact: true,
  },
  {
    key: 'ForgotPasswordPage',
    label: 'ForgotPassword',
    icon: 'user',

    path: '/forgot-password',
    component: ForgotPasswordPage,
    exact: true,
  },
  {
    key: 'ResetPasswordPage',
    label: 'ResetPassword',
    icon: 'user',
    path: '/reset-password',
    component: ResetPasswordPage,
    exact: true,
  },
];
export default routes;
