import cn from 'classnames';
import { get } from 'lodash';
import { cloneElement, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@socialbrothers/components/UI';

import styles from './Repeater.module.scss';
import { RepeaterProps } from './Repeater.props';

const Repeater = ({ children, label, entryName, className, source, ...props }: RepeaterProps) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: source,
  });

  const alterSourceProp = (element: JSX.Element, index: number, field: any) => {
    return cloneElement(element, {
      name: `${source}.${index}.${element.props.name}`,
      defaultValue: field[element.props.name],
    });
  };

  const alterInputProp = (element: JSX.Element, index: number, field: any): JSX.Element => {
    if (!element.props.children && element.props.name) {
      return alterSourceProp(element, index, field);
    } else {
      return cloneElement(element, {
        children: alterInputProp(element.props.children, index, field),
      });
    }
  };

  const cloneChildren = (index: number, field: any) => {
    if (Array.isArray(children)) {
      return children.map((item) => alterInputProp(item, index, field));
    }

    return alterInputProp(children, index, field);
  };

  const handleRemove = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove],
  );

  const handleAppend = useCallback(() => {
    append({});
  }, [append]);

  const getGroup = (index: number, field: any) => {
    return (
      <div className={styles.Field} key={field.id}>
        <div className={styles.Field__Header}>
          <div className={styles.Field__Title}>{`${entryName} ${index + 1}`}</div>
          <div onClick={handleRemove(index)}>
            <Icon icon="trash-alt" type="regular" className={styles.Field__Delete} />
          </div>
        </div>

        {cloneChildren(index, field)}
      </div>
    );
  };

  const errorMessage = get(formState.errors, source)?.message;

  return (
    <div
      className={cn(styles.Repeater, className, {
        [styles['Repeater--Error']]: !!errorMessage,
      })}
      {...props}>
      <fieldset className={styles.Fieldset}>
        <legend>{label}</legend>

        <div className={styles.Fields}>
          {fields.map((field, index) => {
            return getGroup(index, field);
          })}

          <div className={styles.Field}>
            <Button className={styles.Add} icon="plus" type="button" onClick={handleAppend}>
              {t('FORMS.REPEATER.ADD_ENTRY', { entryName })}
            </Button>
          </div>
        </div>
      </fieldset>
      {!!errorMessage && <div className={styles.Repeater__Error}>{errorMessage}</div>}
    </div>
  );
};

export default Repeater;
