import { ResourceService } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { IThemeResultCondition } from './ThemeResultConditionService.props';

export class ThemeResultConditionImplementation extends ResourceService<IThemeResultCondition> {}

export const ThemeResultConditionService = new ThemeResultConditionImplementation(
  AppService,
  '/admin/themeresultconditions',
);
