import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Form } from '@socialbrothers/components/Containers';
import { enumToOptions, isCreate } from '@socialbrothers/utils';
import { Yup } from '@socialbrothers/utils';

import {
  CompanyBrand,
  CompanySelfTestMethod,
  CompanyService,
  CompanySize,
  CompanyType,
} from '@Services/Company';

export const CompanyForm = () => {
  const { id } = useParams<{ id: string }>();
  const [assignRandomSelfTestMethods, setAssignRandomSelfTestMethods] = useState(false);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    brand: Yup.string().required(),
    selfTestMethod: Yup.string().when('assignRandomSelfTestMethods', {
      is: false,
      then: (s) => s.required(),
    }),
    type: Yup.string().required(),
    size: Yup.string().required(),
  });

  return (
    <Form.Resource
      service={CompanyService}
      validationSchema={validationSchema}
      label={t('COMPANY.SINGLE')}
      id={id}>
      <Form.Layout.Field translationKey="COMPANY.LABELS.TITLE">
        <Form.Input.Text name="title" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY.LABELS.BRAND">
        <Form.Input.Select name="brand" options={enumToOptions(CompanyBrand, 'COMPANY_BRAND')} />
      </Form.Layout.Field>

      <Form.Layout.Group label={t('COMPANY.LABELS.SELF_TEST_GROUP')}>
        <Form.Layout.Field
          translationKey="COMPANY.LABELS.ASSIGNRANDOMSELFTESTMETHODS"
          onChange={(value) => {
            setAssignRandomSelfTestMethods(value);
          }}>
          <Form.Input.Checkbox
            name="assignRandomSelfTestMethods"
            options={[
              {
                key: 'true',
                value: String(t('COMPANY.LABELS.ASSIGNRANDOMSELFTESTMETHODS_VALUE')),
                disabled: !isCreate(id),
              },
            ]}
          />
        </Form.Layout.Field>

        {!assignRandomSelfTestMethods && (
          <Form.Layout.Field translationKey="COMPANY.LABELS.SELF_TEST_METHOD">
            <Form.Input.Select
              name="selfTestMethod"
              options={enumToOptions(CompanySelfTestMethod, 'COMPANY_SELF_TEST_METHOD')}
              disabled={!isCreate(id)}
            />
          </Form.Layout.Field>
        )}
      </Form.Layout.Group>

      <Form.Layout.Field translationKey="COMPANY.LABELS.TYPE">
        <Form.Input.Select name="type" options={enumToOptions(CompanyType, 'COMPANY_TYPE')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY.LABELS.SIZE">
        <Form.Input.Select name="size" options={enumToOptions(CompanySize, 'COMPANY_SIZE')} />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="COMPANY.LABELS.IS_PRIVATE">
        <Form.Input.Toggle name="isPrivate" label={t('COMPANY.LABELS.IS_PRIVATE_LABEL')} />
      </Form.Layout.Field>
    </Form.Resource>
  );
};
