import { ResourceService } from '@socialbrothers/services';

import { AppService } from '@Services/index';

import { IConcertoQuestion } from '.';
import { IQuestionnaireQuestion } from './QuestionnaireQuestionService.props';

export class QuestionnaireQuestionServiceImplementation extends ResourceService<IQuestionnaireQuestion> {
  moveUp = async (id: string) => {
    const response = await this.client.post<IQuestionnaireQuestion>(
      `${this.endpoint}/${id}/moveup`,
    );

    return response.data;
  };

  moveDown = async (id: string) => {
    const response = await this.client.post<IQuestionnaireQuestion>(
      `${this.endpoint}/${id}/movedown`,
    );

    return response.data;
  };

  duplicate = async (id: string) => {
    const response = await this.client.post<IQuestionnaireQuestion>(
      `${this.endpoint}/${id}/duplicate`,
    );

    return response.data;
  };

  getConcertoQuestions = async (tableName: string) => {
    const response = await this.client.get<IConcertoQuestion[]>(
      `${this.endpoint}/concerto/questions/${tableName}`,
    );

    return response.data;
  };
}

export const QuestionnaireQuestionService = new QuestionnaireQuestionServiceImplementation(
  AppService,
  '/admin/questionnairequestions',
);
