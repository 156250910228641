import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { Color, Operators } from '@socialbrothers/constants';
import { useList, useResource } from '@socialbrothers/hooks';
import { isCreate } from '@socialbrothers/utils';

import { CompanyForm } from '@Components/forms';
import { CompanyDisivionTable } from '@Components/tables';
import { CompanyService, ICompany } from '@Services/Company';
import { CompanyDivisionService, ICompanyDivision } from '@Services/CompanyDivision';

export const CompanyDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const company = useResource<ICompany>(CompanyService, id);
  const hasDivisions =
    useList<ICompanyDivision>(CompanyDivisionService, {
      filtersJson: {
        companyId: [
          {
            operator: Operators.IS,
            value: id,
          },
        ],
      },
    }).data?.total !== 0;

  const label = company.data?.title || t('COMPANY.SINGLE');

  return (
    <Page title={label}>
      <Card title={t('COMPANY.SINGLE')}>
        <CompanyForm />
      </Card>

      {!isCreate(id) && (
        <Card title={t('COMPANY_DIVISION.PLURAL')}>
          {!hasDivisions && <strong className="d-block mb-sm">{t('COMPANY.NO_DIVISIONS')}</strong>}

          <Button
            icon="plus"
            to={`/companies/${id}/create`}
            color={Color.SECONDARY}
            className={hasDivisions ? 'float-right ml-sm' : ''}>
            {t('GLOBAL.CREATE_MODEL', { name: t('COMPANY_DIVISION.SINGLE') })}
          </Button>

          {hasDivisions && <CompanyDisivionTable companyId={String(id)} />}
        </Card>
      )}
    </Page>
  );
};
