import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { QuestionnaireConfigurator } from '@Components/modules';
import { QuestionnaireType } from '@Services/Questionnaire';

export const QuestionnaireSelftestPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('QUESTIONNAIRE.TYPE.SELFTEST')}>
      <Card title={t('QUESTIONNAIRE.TYPE.SELFTEST')}>
        <QuestionnaireConfigurator type={QuestionnaireType.SELFTEST} />
      </Card>
    </Page>
  );
};
