import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { useTheme } from '@socialbrothers/hooks';
import { Yup } from '@socialbrothers/utils';

import styles from './AccountForm.module.scss';
import { AccountFormValues } from './AccountForm.props';

export const AccountForm = ({ ...props }) => {
  const { t } = useTranslation();
  const { accountService } = useTheme();
  const queryClient = useQueryClient();
  const [changePassword, setChangePassword] = useState(false);
  const user = useQuery(['users', 'me'], accountService.me);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    phoneNumber: Yup.string().required(),
    password: Yup.string().when('password_change', {
      is: 'true',
      then: (s) => s.password().required(),
    }),
    passwordConfirmation: Yup.string().when('password_change', {
      is: 'true',
      then: (s) => s.password('password').required(),
    }),
  });

  const mutateUpdateMe = useMutation(accountService.updateMe, {
    onSuccess: () => {
      queryClient.invalidateQueries(accountService.endpoint);

      toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('ACCOUNT.YOUR_ACCOUNT') }));
    },
    onError: () => {
      toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('ACCOUNT.YOUR_ACCOUNT') }));
    },
  });

  const handleSubmit = (values: AccountFormValues) => {
    const params = {
      ...values,
      id: user.data?.id || '',
      password: values.password || undefined,
    };

    return mutateUpdateMe.mutateAsync(params);
  };

  return (
    <Form.Base
      className={styles.AccountForm}
      onSubmit={handleSubmit}
      submitLabel={t('GLOBAL.SAVE')}
      validationSchema={validationSchema}
      initialValues={user.data}
      {...props}>
      <Form.Layout.Field translationKey="FORMS.COMMON_LABELS.EMAIL">
        <Form.Input.Text name="email" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USERS.LABELS.FIRST_NAME">
        <Form.Input.Text name="firstName" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USERS.LABELS.LAST_NAME">
        <Form.Input.Text name="lastName" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="USERS.LABELS.PHONE_NUMBER">
        <Form.Input.Text name="phoneNumber" />
      </Form.Layout.Field>

      <Form.Layout.Field
        translationKey="USERS.PASSWORD_CHANGE"
        onChange={(value) => {
          setChangePassword(value);
        }}>
        <Form.Input.Checkbox
          name="password_change"
          options={[{ key: 'true', value: String(t('USERS.PASSWORD_CHANGE')) }]}
        />
      </Form.Layout.Field>

      {changePassword && (
        <>
          <Form.Layout.Field translationKey="USERS.LABELS.PASSWORD">
            <Form.Input.Text type="password" name="password" />
          </Form.Layout.Field>

          <Form.Layout.Field translationKey="USERS.LABELS.PASSWORD_CONFIRMATION">
            <Form.Input.Text type="password" name="passwordConfirmation" />
          </Form.Layout.Field>
        </>
      )}
    </Form.Base>
  );
};
