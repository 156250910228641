import { BaseModel } from '@socialbrothers/constants';

import { IQuestionnaireSection } from '@Services/QuestionnaireSection';
import { ITheme } from '@Services/Theme';

export enum ThemeResultConditionType {
  SCORE_RANGE_AVERAGE = 'SCORE_RANGE_AVERAGE',
  SCORE_RANGE_SUM = 'SCORE_RANGE_SUM',
  SUBSCALE = 'SUBSCALE',
}

export enum ThemeResultConditionResult {
  RED = 'RED',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export interface IThemeResultCondition extends BaseModel {
  themeId: string;
  theme?: ITheme;
  type: ThemeResultConditionType;
  result: ThemeResultConditionResult;
  min?: number;
  max?: number;
  subscale1Id?: string;
  subscale1?: IQuestionnaireSection;
  subscale1Result?: ThemeResultConditionResult;
  subscale2Id?: string;
  subscale2?: IQuestionnaireSection;
  subscale2Result?: ThemeResultConditionResult;
}
