import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Switch, useLocation } from 'react-router-dom';

import { Role } from '@socialbrothers/constants';
import { AppRoutes, AuthRoutes } from '@socialbrothers/routes';
import { getRoutesFromConfig, hasRole } from '@socialbrothers/utils';

import { useStores } from '@Hooks/index';

import { routes } from './routes';

const Scroll = ({ children }: { children: any }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

const RootNavigator = () => {
  const { AuthStore } = useStores();

  const allRoutes = [...(!AuthStore.isAuthenticated ? AuthRoutes : [...AppRoutes, ...routes])];

  return (
    <Router>
      <Scroll>
        <Switch>
          {getRoutesFromConfig(allRoutes)}
          {AuthStore.isAuthenticated ? (
            <Redirect to={`${hasRole(Role.Admin) ? '/companies' : '/selftest'}`} />
          ) : (
            <Redirect to="/" />
          )}
        </Switch>
      </Scroll>
    </Router>
  );
};

export default observer(RootNavigator);
