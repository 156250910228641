import { ListResponse } from '@socialbrothers/constants';
import { Service } from '@socialbrothers/services';

import { AppService } from '@Services/index';
import { getCurrentDateTime } from '@Utils/DateUtils';
import { downloadFile } from '@Utils/FileUtils';

import { IPersona } from './PersonaService.props';

class PersonaServiceImplementation extends Service {
  getPersonas = async (): Promise<ListResponse<IPersona>> => {
    const response = await this.client.get<ListResponse<IPersona>>(this.endpoint, {
      perPage: 9999,
      sortBy: 'title',
    });

    return response.data;
  };

  exportPersona = async (): Promise<any> => {
    const response = await this.client.get(`${this.endpoint}/export`);

    downloadFile(JSON.stringify(response.data), `export_${getCurrentDateTime()}.json`);

    return response.data;
  };
}

export const PersonaService = new PersonaServiceImplementation(AppService, '/admin/personas');
