import { ToastContainer } from 'react-toastify';

import './App.scss';

import { ThemeProvider, useSentry } from '@socialbrothers/hooks';
import { yupSetup } from '@socialbrothers/utils';

import config from '@Config';
import { AuthService } from '@Services/Auth';
import { UserService } from '@Services/User';

import { RootNavigator } from './routes';

export const context = {
  authService: AuthService,
  accountService: UserService,
  config: config,
};

yupSetup();

const App = () => {
  useSentry();

  return (
    <ThemeProvider context={context}>
      <ToastContainer />
      <RootNavigator />
    </ThemeProvider>
  );
};

export default App;
