import cn from 'classnames';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import { useOnClickOutside, useToggle } from '@socialbrothers/hooks';

import Icon from '../Icon/Icon';
import styles from './DropdownButton.module.scss';
import { DropdownButtonProps } from './DropdownButton.props';

const DropdownButton = ({ children, items, className, ...props }: DropdownButtonProps) => {
  const { toggle, isToggle, setToggle } = useToggle(false);
  const ref = useRef<any>();

  useOnClickOutside(ref, () => {
    setToggle(false);
  });

  return (
    <div ref={ref} className={styles.DropdownButton} {...props}>
      <div onClick={toggle} className={styles.DropdownButton__Button}>
        {children}
        <Icon className={styles.DropdownButton__Chevron} type="regular" icon="chevron-down" />
      </div>

      {isToggle && (
        <div className={styles.DropdownButton__Dropdown}>
          {items.map((item, idx) => {
            return item.onPress ? (
              <span
                key={idx}
                className={cn(
                  styles.DropdownButton__Item,
                  styles['DropdownButton__Item--Clickable'],
                )}
                onClick={item.onPress}>
                {item.label}
              </span>
            ) : (
              <Link to={item.path as string} className={styles.DropdownButton__Item} key={idx}>
                {item.label}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default DropdownButton;
