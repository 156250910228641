import React from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { AccountForm } from '@Components/forms';

export const AccountPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('ACCOUNT.TITLE')}>
      <Card title="Account">
        <AccountForm />
      </Card>
    </Page>
  );
};

export default AccountPage;
