import { IQuestionnaire } from '@Services/Questionnaire';
import { IQuestionnaireQuestion, QuestionnaireQuestionType } from '@Services/QuestionnaireQuestion';
import { IQuestionnaireSection } from '@Services/QuestionnaireSection';

export const getQuestionsFromQuestionnaire = (questionnaire: IQuestionnaire) => {
  let questions: IQuestionnaireQuestion[] = [];

  const iterate = (section: IQuestionnaireSection): any => {
    if (section.questions.length > 0) {
      questions = [...questions, ...section.questions];
    }

    if (section.childSections.length > 0) {
      section.childSections.forEach(iterate);
    }
  };

  questionnaire.rootSections.forEach(iterate);

  return questions;
};

export const hasAnswers = (type: QuestionnaireQuestionType) => {
  const types = [
    QuestionnaireQuestionType.CAROUSEL,
    QuestionnaireQuestionType.DROPDOWN,
    QuestionnaireQuestionType.RADIO,
    QuestionnaireQuestionType.SLIDER_STEP,
    QuestionnaireQuestionType.SLIDER_EMOJI,
  ];

  return types.includes(type);
};

export const hasScaleDescription = (type: QuestionnaireQuestionType) => {
  const types = [QuestionnaireQuestionType.SLIDER];

  return types.includes(type);
};

export const isConcertoSection = (section?: IQuestionnaireSection) => {
  return !!section?.concertoQuestionTableName && !!section?.concertoTestSlug;
};

export const isConcertoQuestion = (question?: IQuestionnaireQuestion) => {
  return !!question?.concertoQuestionId;
};
