import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { SubscaleForm } from '@Components/forms';
import { SubscaleTable } from '@Components/tables';

export const SubscaleOverviewPage = () => {
  const { t } = useTranslation();

  const buttons = <SubscaleForm />;

  return (
    <Page title={t('SUBSCALE.PLURAL')} buttons={buttons}>
      <Card title={t('SUBSCALE.PLURAL')}>
        <SubscaleTable />
      </Card>
    </Page>
  );
};
