import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useBreadcrumbs, useResource } from '@socialbrothers/hooks';
import { isCreate } from '@socialbrothers/utils';

import { CompanyDivisionForm } from '@Components/forms';
import { CompanyDivisionUserTable } from '@Components/tables';
import { routes } from '@Routes/routes';
import { CompanyService, ICompany } from '@Services/Company';
import { CompanyDivisionService, ICompanyDivision } from '@Services/CompanyDivision';

export const CompanyDivisionDetailPage = () => {
  const { t } = useTranslation();
  const { companyId, id } = useParams<{ companyId: string; id: string }>();

  const company = useResource<ICompany>(CompanyService, companyId);
  const division = useResource<ICompanyDivision>(CompanyDivisionService, id);

  const label = division.data ? division.data.title : t('COMPANY_DIVISION.SINGLE');
  let items = useBreadcrumbs(routes, label);

  if (items[1] && items[1].key === 'CompanyDetailPage' && company.data) {
    items[1].label = company.data.title;
  }

  return (
    <Page title={label} breadcrumbs={items}>
      <Card title={label}>
        <CompanyDivisionForm companyId={companyId} />
      </Card>

      {!isCreate(id) && (
        <Card title={t('USER.PLURAL')}>
          <CompanyDivisionUserTable divisionId={id} />
        </Card>
      )}
    </Page>
  );
};
