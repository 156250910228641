import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { Operators, Role } from '@socialbrothers/constants';

import { IUser, UserService } from '@Services/User';

import { AdminTableProps } from './AdminTable.props';

export const AdminTable = ({ className }: AdminTableProps) => {
  const { t } = useTranslation();

  return (
    <Table.Resource<IUser>
      searchable
      defaultFilters={{
        'Roles.NormalizedName': [
          {
            operator: Operators.IN,
            value: [Role.Admin, Role.RND].join(','),
          },
        ],
      }}
      service={UserService}
      className={className}>
      <Table.Field.Text sortable source="firstName" label={t('ADMIN.LABELS.FIRST_NAME')} />
      <Table.Field.Text sortable source="lastName" label={t('ADMIN.LABELS.LAST_NAME')} />
      <Table.Field.Email sortable source="email" label={t('ADMIN.LABELS.EMAIL')} />
      <Table.Field.Text sortable source="phoneNumber" label={t('ADMIN.LABELS.PHONE_NUMBER')} />
      <Table.Field.Chip source="roles" render={(roles) => roles} label={t('ADMIN.LABELS.ROLE')} />
      <Table.Field.ResourceAction
        deleteConfig={(record: IUser) => ({
          name: `${record.firstName} ${record.lastName}`,
        })}
        editConfig={(record: IUser) => ({
          link: `/administrators/${record.id}`,
        })}
      />
    </Table.Resource>
  );
};
