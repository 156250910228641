import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { Color } from '@socialbrothers/constants';
import { enumToOptions, modelToOptions, Yup } from '@socialbrothers/utils';

import { usePersonas } from '@Hooks/index';
import { useQuestionnaireConfigurator } from '@Hooks/index';
import { useConcertoQuestions } from '@Hooks/useConcertoQuestions';
import { IPersona } from '@Services/Persona';
import { QuestionnaireType } from '@Services/Questionnaire';
import {
  QuestionnaireQuestionService,
  QuestionnaireQuestionType,
} from '@Services/QuestionnaireQuestion';
import {
  hasScaleDescription,
  isConcertoQuestion,
  isConcertoSection,
} from '@Utils/QuestionnaireUtils';

import { QuestionFormFormProps, QuestionFormProps } from './QuestionForm.props';

export const QuestionForm = ({ question, section }: QuestionFormProps) => {
  const { t } = useTranslation();
  const personas = usePersonas();
  const queryClient = useQueryClient();
  const questionnaire = useQuestionnaireConfigurator();
  const isPersona = questionnaire.type === QuestionnaireType.PERSONA;
  const [isConcerto, setIsConcerto] = useState(isConcertoQuestion(question));

  const [type, setType] = useState<QuestionnaireQuestionType | undefined>(
    isPersona ? QuestionnaireQuestionType.SLIDER : question?.type,
  );

  const concertoQuestions = useConcertoQuestions(section.concertoQuestionTableName);

  const mutateCreate = useMutation(
    (values: QuestionFormFormProps) =>
      QuestionnaireQuestionService.create({
        questionnaireSectionId: section.id,
        ...values,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);

        toast.success(
          t('GLOBAL.CREATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.SINGLE') }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.CREATED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.SINGLE'),
          }),
        );
      },
    },
  );

  const mutateUpdate = useMutation(
    (values: QuestionFormFormProps) =>
      QuestionnaireQuestionService.update(question ? question.id : '', values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([questionnaire.type]);

        toast.success(
          t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.SINGLE') }),
        );
      },

      onError: () => {
        toast.error(
          t('GLOBAL.UPDATED_UNSUCCESSFUL', {
            name: t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.SINGLE'),
          }),
        );
      },
    },
  );

  const validationSchema = Yup.object().shape({
    headerTitle: Yup.string().required(),
    type: Yup.string().required(),
    scaleMinDescription: type && hasScaleDescription(type) ? Yup.string().required() : Yup.string(),
    scaleMaxDescription: type && hasScaleDescription(type) ? Yup.string().required() : Yup.string(),
    personaScores:
      isPersona && type === QuestionnaireQuestionType.SLIDER
        ? Yup.array().of(
            Yup.object().shape({
              personaId: Yup.string().required(),
              score: Yup.number().min(0).max(100).required(),
            }),
          )
        : Yup.object().shape({}),
    label: Yup.string(),
    concertoQuestionId: Yup.string().when('isConcertoQuestion', {
      is: 'true',
      then: (s) => s.required(),
    }),
  });

  const PersonaScoreFields = useCallback(
    (idx: number, persona: IPersona) => {
      return (
        <div key={idx}>
          <Form.Input.Hidden name={`personaScores.${idx}.personaId`} value={persona.id} />

          <Form.Layout.Group label={persona.title}>
            <Form.Layout.Field
              label={t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.PERSONA_SCORES_SCORE')}>
              <Form.Input.Number
                name={`personaScores.${idx}.score`}
                min={0}
                max={100}
                defaultValue={0}
              />
            </Form.Layout.Field>

            <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.PERSONA_SCORES_WEIGHS_DOUBLE">
              <Form.Input.Toggle
                name={`personaScores.${idx}.weighsDouble`}
                label={t(
                  'QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.PERSONA_SCORES_WEIGHS_DOUBLE_LABEL',
                )}
              />
            </Form.Layout.Field>
          </Form.Layout.Group>
        </div>
      );
    },
    [t],
  );

  return (
    <ButtonWithPopup
      withForm
      validationSchema={validationSchema}
      initialValues={{
        ...question,
        type: isPersona ? QuestionnaireQuestionType.SLIDER : question?.type,
        isConcertoQuestion: isConcerto,
      }}
      button={{
        color: question ? Color.PRIMARY : Color.SECONDARY,
        icon: question ? 'pencil' : 'plus',
        link: !!question,
        label: question ? '' : t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.CREATE'),
      }}
      submit={{
        label: question ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
        onClick: (values: QuestionFormFormProps) => {
          return question ? mutateUpdate.mutateAsync(values) : mutateCreate.mutateAsync(values);
        },
      }}
      popup={{
        title: question
          ? t('GLOBAL.UPDATE_MODEL', {
              name: question.title,
            })
          : t('GLOBAL.CREATE_MODEL', {
              name: t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.SINGLE'),
            }),
      }}>
      <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.HEADER_TITLE">
        <Form.Input.Text name="headerTitle" />
      </Form.Layout.Field>

      <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.HEADER_DESCRIPTION">
        <Form.Input.RichText name="headerDescription" />
      </Form.Layout.Field>

      <Form.Layout.Group label={t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.QUESTION')}>
        <p>{t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.QUESTION_DESCRIPTION')}</p>

        <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.TITLE">
          <Form.Input.Text name="title" />
        </Form.Layout.Field>

        <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.DESCRIPTION">
          <Form.Input.RichText name="description" />
        </Form.Layout.Field>

        <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.LABEL">
          <Form.Input.Text name="label" />
        </Form.Layout.Field>

        <Form.Layout.Field
          translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.TYPE"
          onChange={setType}>
          <Form.Input.Select
            name="type"
            disabled={isPersona || !!question}
            options={enumToOptions(QuestionnaireQuestionType, 'QUESTIONNAIRE_QUESTION_TYPE')}
          />
        </Form.Layout.Field>

        {type && hasScaleDescription(type) && (
          <>
            <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.SCALE_MIN_DESCRIPTION">
              <Form.Input.Text name="scaleMinDescription" />
            </Form.Layout.Field>

            <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.SCALE_MAX_DESCRIPTION">
              <Form.Input.Text name="scaleMaxDescription" />
            </Form.Layout.Field>
          </>
        )}

        {!isPersona && isConcertoSection(section) && (
          <>
            <Form.Layout.Group label={t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.CONCERTO')}>
              {concertoQuestions.isError && (
                <div>
                  {t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.CONCERTO_QUESTION_ID_ERROR', {
                    name: section.concertoQuestionTableName,
                  })}
                </div>
              )}
              {concertoQuestions.data && (
                <>
                  <Form.Layout.Field
                    translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.IS_CONCERTO_QUESTION"
                    onChange={setIsConcerto}>
                    <Form.Input.Toggle
                      name="isConcertoQuestion"
                      label={t(
                        'QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.IS_CONCERTO_QUESTION_TOGGLE',
                      )}
                    />
                  </Form.Layout.Field>

                  {isConcerto && (
                    <>
                      <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.CONCERTO_QUESTION_ID">
                        <Form.Input.Select
                          name="concertoQuestionId"
                          options={modelToOptions(
                            concertoQuestions.data || [],
                            (x) => `${x.id}. ${x.trait} - ${x.question}`,
                          )}
                        />
                      </Form.Layout.Field>

                      <Form.Layout.Field translationKey="QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.ADAPTIVE_ONLY">
                        <Form.Input.Checkbox
                          name="adaptiveOnly"
                          options={[
                            {
                              key: 'true',
                              value: String(
                                t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.ADAPTIVE_ONLY_LABEL'),
                              ),
                            },
                          ]}
                        />
                      </Form.Layout.Field>
                    </>
                  )}
                </>
              )}
            </Form.Layout.Group>
          </>
        )}
      </Form.Layout.Group>

      {isPersona && type === QuestionnaireQuestionType.SLIDER && (
        <Form.Layout.Group label={t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.PERSONA_SCORES')}>
          <p>{t('QUESTIONNAIRE.CONFIGURATOR.QUESTION.LABELS.PERSONA_SCORES_DESCRIPTION')}</p>

          {!question &&
            personas.data?.results.map((persona, idx: number) => {
              return PersonaScoreFields(idx, persona);
            })}

          {question &&
            question.personaScores.map((personaScore, idx: number) => {
              return PersonaScoreFields(idx, personaScore.persona);
            })}
        </Form.Layout.Group>
      )}
    </ButtonWithPopup>
  );
};
